import React from "react";
import Topbar from "./components/Topbar/Topbar";
import Applicationform from "./pages/Applicationform/Applicationform";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import Login from "./pages/Login/Login";
import ThankYou from "./pages/ThankYou/ThankYou";
import Error from "./pages/Error/Error";

function App() {
    const history = useHistory();
    return (
       <Router>
           <Topbar history={history}/>
           <Switch>
               <Route exact path="/">
                   <Applicationform/>
               </Route>
               <Route path="/admin">
                   <Login/>
               </Route>
               <Route path="/thank-you">
                   <ThankYou/>
               </Route>
               <Route path="/error">
                   <Error/>
               </Route>
           </Switch>
       </Router>
    );
}

export default App;
