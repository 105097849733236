import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Container, Paper} from "@mui/material";
import MultiStepForm, {FormStep} from "./MultiStepForm";
import Box from "@mui/material/Box";
import * as Yup from 'yup';
import JobDescription from "../JobDescription/JobDescription";
import PersonalInfo from "../PersonalInfo/PersonalInfo";
import ContactInfo from "../ContactInfo/ContactInfo";
import AboutInfo from "../AboutInfo/AboutInfo";
import Agreement from "../Agreement/Agreement";
import EducationInfo from "../EducationInfo/EducationInfo";
import EmploymentInfo from "../EmploymentInfo/EmploymentInfo";
import axios from "axios";
import {useHistory} from "react-router-dom";
import AvailableJobs from "../AvailableJobs/AvailableJobs";

const FORM_INTERFACE = {
    jobDescription: [],
    email: "",
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    qualification: "",
    degree: "",
    institute: "",
    marks: "",
    project: "",
    salary: "",
    dob: "",
    language: [],
    religion: "",
    caste: "",
    numberOfDependents: "",
    accommodation: "",
    vehicle: "",
    location: "",
    workStatus: "",
    experience: "",
    dateStartWork: "",


    currentAddress: "",
    currentPincode: "",
    phoneNo: "",
    permanentAddress: "",
    permanentPincode: "",
    districtState: "",
    emergencyContact: "",

    aboutYourself: "",
    summary: "",
    question_1: "",
    question_2: "",

    noOfWE: 1,
    employmentHistory: [
        {
            currentOrLastPosition: '',
            nameOfEmployer: '',
            address: '',
            reportingManager: '',
            jobDuration: '',
            reasonForLeaving: ''
        },
    ],

    sscXInstitute: "",
    sscXYear: "",
    sscXBoard: "",
    sscXCity: "",
    sscXMarks: "",

    hscXIIInstitute: "",
    hscXIIYear: "",
    hscXIIBoard: "",
    hscXIIMarks: "",
    hscXIICity: "",

    diplomaInstitute: "",
    diplomaYear: "",
    diplomaStream: "",
    diplomaCity: "",
    diplomaMarks: "",

    gradInstitute: "",
    gradYear: "",
    gradStream: "",
    gradMarks: "",
    gradCity: "",

    postGradInstitute: "",
    postGradYear: "",
    postGradStream: "",
    postGradCity: "",
    postGradMarks: "",

    skills: "",
    achievements: "",
    itAndPresentation: [],
    digitalSkills: [],


    agreement: []
}

const JOB_DESCRIPTION_VALIDATION_SCHEME = Yup.object().shape({
    jobDescription: Yup.array()
        .min(1, 'Please agree to submit the form.')
        .required('Required')
})

const PERSONAL_INFO_VALIDATION_SCHEME = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email')
        .required('Required'),
    firstName: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    middleName: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    lastName: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    qualification: Yup.string()
        .required('Required'),
    degree: Yup.string()
        .required('Required'),
    institute: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    marks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Please enter a positive number')
        .max(100, 'Please enter a valid percentage')
        .required('Required'),
    salary: Yup.number('Invalid salary')
        .typeError('Enter a positive integer')
        .positive('Enter a positive integer')
        .integer('Invalid field')
        .required('Required'),
    dob: Yup.date()
        .required('Required'),
    language: Yup.array(),
    religion: Yup.string()
        .max(10, 'Please limit to 10 characters'),
    caste: Yup.string()
        .max(10, 'Please limit to 10 characters'),
    numberOfdependents: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive integer')
        .integer('Invalid field'),
    accommodation: Yup.string(),
    vehicle: Yup.string()
        .required('Required'),
    location: Yup.string()
        .required('Required'),
    workStatus: Yup.string()
        .required('Required'),
    experience: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive integer')
        .min(0,)
        .max(100, 'Please enter a number less than 100')
        .integer('Invalid field')
        .required('Required'),
    dateStartWork: Yup.date()
        .required('Required')
})


const CONTACT_INFO_VALIDATION_SCHEME = Yup.object().shape({
    currentAddress: Yup.string()
        .max(150, 'Please limit to 150 characters')
        .required('Required'),
    currentPincode: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive integer')
        .integer('Invalid field')
        .test('len', 'Must be exactly 6 characters', (val) => {
            if (val) return val.toString().length === 6;
        })
        .required('Required'),
    permanentAddress: Yup.string()
        .max(150, 'Please limit to 150 characters')
        .required('Required'),
    permanentPincode: Yup.number()
        .typeError('Enter a positive integer')
        .test('len', 'Must be exactly 6 characters', (val) => {
            if (val) return val.toString().length === 6;
        })
        .positive('Enter a positive integer')
        .integer('Invalid field')
        .required('Required'),
    districtState: Yup.string()
        .max(50, 'Please limit to 50 characters')
        .required('Required'),
    emergencyContact: Yup.number()
        .typeError('Enter a positive integer')
        .test('len', 'Must be exactly 10 characters', (val) => {
            if (val) return val.toString().length === 10;
        })
        .positive('Enter a positive integer')
        .integer('Invalid field')
        .required('Required'),
    phoneNo: Yup.number()
        .typeError('Enter a positive integer')
        .test('len', 'Must be exactly 10 characters', (val) => {
            if (val) return val.toString().length === 10;
        })
        .positive('Enter a positive integer')
        .integer('Invalid field')
        .required('Required')
})

const AGREEMENT_VALIDATION_SCHEMA = Yup.object().shape({
    agreement: Yup.array()
        .min(1, 'Please agree to submit the form.')
        .required('Required')
})

const WORK_EXPERIENCE_INFO_VALIDATION_SCHEMA = Yup.object().shape({
    employmentHistory: Yup.array()
        .of(
            Yup.object().shape({
                currentOrLastPosition: Yup.string()
                    .max(100, 'Please limit to 100 characters')
                    .required('Required'),
                nameOfEmployer: Yup.string()
                    .max(100, 'Please limit to 100 characters')
                    .required('Required'),
                address: Yup.string()
                    .max(150, 'Please limit to 150 characters')
                    .required('Required'),
                reportingManager: Yup.string()
                    .max(100, 'Please limit to 100 characters')
                    .required('Required'),
                jobDuration: Yup.string()
                    .max(100, 'Please limit to 100 characters')
                    .required('Required'),
                reasonForLeaving: Yup.string()
                    .max(200, 'Please limit to 200 characters')
                    .required('Required')
            }))
        .min(1, 'Required'),
})


const EDUCATION_INFO_VALIDATION_SCHEMA = Yup.object().shape({
    sscXInstitute: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    sscXYear: Yup.number()
        .positive('Please enter a positive year')
        .test('len', 'Must be exactly 4 characters', (val) => {
            if (val) return val.toString().length === 4;
        })
        .required('Required'),
    sscXBoard: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    sscXCity: Yup.string()
        .max(50, 'Please limit to 50 characters')
        .required('Required'),
    sscXMarks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive number')
        .max(100, 'Please enter a value less than 100')
        .required('Required'),


    hscXIIInstitute: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    hscXIIYear: Yup.number()
        .positive('Please enter a positive year'),
    hscXIIBoard: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    hscXIICity: Yup.string()
        .max(50, 'Please limit to 50 characters'),
    hscXIIMarks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive number')
        .max(100, 'Please enter a value less than 100'),

    diplomaInstitute: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    diplomaYear: Yup.number()
        .positive('Please enter a positive year'),
    diplomaStream: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    diplomaCity: Yup.string()
        .max(50, 'Please limit to 50 characters'),
    diplomaMarks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive number')
        .max(100, 'Please enter a value less than 100'),

    gradInstitute: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    gradYear: Yup.number()
        .positive('Please enter a positive year')
        .test('len', 'Must be exactly 4 characters', (val) => {
            if (val) return val.toString().length === 4;
        })
        .required('Required'),
    gradStream: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),
    gradCity: Yup.string()
        .max(50, 'Please limit to 50 characters')
        .required('Required'),
    gradMarks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive number')
        .max(100, 'Please enter a value less than 100')
        .required('Required'),

    postGradInstitute: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    postGradYear: Yup.number()
        .positive('Please enter a positive year')
        .min(1000)
        .max(3000),
    postGradStream: Yup.string()
        .max(100, 'Please limit to 100 characters'),
    postGradCity: Yup.string()
        .max(50, 'Please limit to 50 characters'),
    postGradMarks: Yup.number()
        .typeError('Enter a positive integer')
        .positive('Enter a positive number')
        .max(100, 'Please enter a value less than 100'),

    skills: Yup.string()
        .max(100, 'Please limit to 100 characters')
        .required('Required'),

    achievements: Yup.string()
        .max(100, 'Please limit to 100 characters'),

    itAndPresentation: Yup.array()
        .required('Required'),
})


const ABOUT_INFO_VALIDATION_SCHEMA = Yup.object().shape({
    aboutYourself: Yup.string()
        .required('Required'),
    summary: Yup.string()
        .required('Required'),
    question_1: Yup.string(),
    question_2: Yup.string(),
})


function FormComponent() {
    const history = useHistory()
    const [on, setOn] = useState(false)

    const [exp, setExp] = useState('0');
    const [final, setFinal] = useState({});

    const [jobs, setJobs] = useState([])
    const [max, setMax] = useState(96)

    const fetchJobs = async () => {
        const response = await axios.get('https://job-application-services.herokuapp.com/api/v1/get/jobs').catch((err) => console.log(err))
        setJobs(response.data)

        response.data.forEach(e => {
            let tmax = max
            if (e.maxCTC < tmax) {
                tmax = e.maxCTC
                setMax(tmax)
            }
        })
    }

    useEffect(() => {
        fetchJobs().catch((err) => history.push('/error'))
    }, [])

    function handleResponse(response) {
        setOn(false)
        if (response.status === 200) {
            history.push("/thank-you")
        } else {
            history.push('/error')
        }
    }

    function handleErr() {
        setOn(false)
        history.push('/error')
    }

    function handleExperience(value) {
        setExp(value)
    }

    function handleSubmit(values) {
        values.fullName = values.firstName.concat(" ", values.middleName, " ", values.lastName)
        setOn(true)
        axios.post("https://job-application-services.herokuapp.com/api/v1/post/application-form", values)
            .then((res) => handleResponse(res))
            .catch((err) => handleErr(err))
    }


    return (
        <div style={{width: '100%'}}>

            <div style={{width: '100%'}}>
                <Paper component={Box} sx={{marginTop: 5, width: '100%'}}>
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={on}
                    >
                        <CircularProgress sx={{
                            color: "#FFC300"
                        }}/>
                    </Backdrop>

                    <MultiStepForm
                        initialValues={FORM_INTERFACE}
                        onSubmit={values => {
                            handleSubmit(values)
                            console.log(JSON.stringify(values))
                        }}>

                        <FormStep
                            stepName={'Job Description'}
                            initialValues={FORM_INTERFACE}
                            onSubmit={(values) => {
                                window.scrollTo(0, 0);
                                console.log('Step 1 Submit', values)
                            }}>
                            <JobDescription/>
                        </FormStep>

                        <FormStep
                            stepName={'Choose Job'}
                            initialValues={FORM_INTERFACE}
                            validationSchema={JOB_DESCRIPTION_VALIDATION_SCHEME}
                            onSubmit={(values) => {
                                window.scrollTo(0, 0);
                                console.log('Step 2 Submit', values)
                            }}>
                            <AvailableJobs/>
                        </FormStep>

                        <FormStep
                            stepName={'Personal Information'}
                            initialValues={FORM_INTERFACE}
                            validationSchema={PERSONAL_INFO_VALIDATION_SCHEME}
                            onSubmit={(values) => {
                                window.scrollTo(0, 0);
                                handleExperience(values.experience)
                                values.fullName = values.firstName.concat(" ", values.middleName, " ", values.lastName)
                                console.log('Step 2 Submit')
                            }}>
                            <PersonalInfo jobs={jobs} maxs={max}/>
                        </FormStep>

                        <FormStep
                            stepName={'Contact Information'}
                            initialValues={FORM_INTERFACE}
                            validationSchema={CONTACT_INFO_VALIDATION_SCHEME}
                            onSubmit={() => {
                                window.scrollTo(0, 0);
                                console.log('Step 3 Submit')
                                console.log(FORM_INTERFACE)
                            }}>
                            <ContactInfo jobs={jobs}/>
                        </FormStep>

                        <FormStep
                            stepName={'About Information'}
                            initialValues={FORM_INTERFACE}
                            validationSchema={ABOUT_INFO_VALIDATION_SCHEMA}
                            onSubmit={() => {
                                window.scrollTo(0, 0);
                                console.log('Step 4 Submit')
                            }}>
                            <AboutInfo jobs={jobs}/>
                        </FormStep>

                        {exp === 0 ?
                            null :
                            <FormStep
                                stepName="Work Experience"
                                initialValues={FORM_INTERFACE}
                                validationSchema={WORK_EXPERIENCE_INFO_VALIDATION_SCHEMA}
                                onSubmit={(values) => {
                                    window.scrollTo(0, 0);
                                    console.log('Step 5 Submit')
                                    if (FORM_INTERFACE.noOfWE > FORM_INTERFACE.employmentHistory.length) {
                                        for (let i = FORM_INTERFACE.employmentHistory.length; i < FORM_INTERFACE.noOfWE; ++i) {
                                            if (FORM_INTERFACE.employmentHistory.length > 0)
                                                FORM_INTERFACE.employmentHistory.pop()
                                        }
                                    } else if (FORM_INTERFACE.noOfWE < FORM_INTERFACE.employmentHistory.length) {
                                        FORM_INTERFACE.noOfWE = FORM_INTERFACE.employmentHistory.length
                                    }
                                    console.log(JSON.stringify(values))
                                }}>

                                <EmploymentInfo jobs={jobs}/>
                            </FormStep>
                        }

                        <FormStep
                            stepName={"Education Information"}
                            initialValues={FORM_INTERFACE}
                            validationSchema={EDUCATION_INFO_VALIDATION_SCHEMA}
                            onSubmit={(values) => {
                                window.scrollTo(0, 0);
                                console.log('Step 6 Submit')
                                setFinal(values)
                                console.log(JSON.stringify(values))
                            }}>
                            <EducationInfo jobs={jobs}/>
                        </FormStep>


                        <FormStep
                            stepName="Agreement"
                            initialValues={FORM_INTERFACE}
                            validationSchema={AGREEMENT_VALIDATION_SCHEMA}
                            onSubmit={() => console.log('Step 7 Submit')}>
                            <Agreement formValues={final}/>
                        </FormStep>

                    </MultiStepForm>
                </Paper>

            </div>
        </div>
    );
}

export default FormComponent;