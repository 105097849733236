import React from 'react';
import {Box, Container, Divider, Paper, Stack, Typography} from "@mui/material";
import Openings from "../JobDescription/Openings";

function AvailableJobs(props) {
    return (
        <div>
            <h2 style={{
                fontFamily: '"Raleway", sans-serif',
                fontSize: 'calc(1.6rem + 1.4vw)',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 10,
            }}>Available Jobs</h2>

            <Stack direction={'row'}>
                <Paper variant="outlined" style={{
                    minHeight: 100,
                    marginTop: 20,
                    maxWidth: '40%',
                    marginLeft: 'auto',
                    marginRight: 20,
                    padding: 30,
                    borderColor: 'rgb(230, 161, 41)'
                }}>

                    <Typography sx={{fontFamily: '"Raleway", sans-serif', fontSize: 16, textAlign: 'center'}}>
                        Please select your experience and educational qualifications to see available job
                        openings.
                    </Typography>

                </Paper>

                <Paper variant="outlined" style={{
                    minHeight: 100,
                    marginTop: 20,
                    maxWidth: '40%',
                    marginLeft: 20,
                    marginRight: 'auto',
                    padding: 30,
                    borderColor: 'rgb(230, 161, 41)'
                }}>

                    <Typography sx={{fontFamily: '"Raleway", sans-serif', fontSize: 16, textAlign: 'center'}}>
                        Select the jobs that you want to apply for and click next at the bottom of the page.
                    </Typography>
                </Paper>


            </Stack>


            <Container style={{marginLeft: '4%', marginRight: '4%', width: '90%', maxWidth: '90%',}}
                       component={Box}>
                <Divider variant="middle" sx={{
                    width: "100%",
                    borderBottomWidth: 5,
                    marginX: 0,
                    marginBottom: 1,
                    marginTop: 3,
                    background: "#000000"
                }}/>
                <Openings/>
            </Container>
        </div>
    );
}

export default AvailableJobs;