import React, {useEffect, useState} from 'react';
import {
    Box, ButtonBase,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem, Paper,
    Typography,
    useTheme
} from "@mui/material";
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import {CurrencyRupee} from "@mui/icons-material";
import {Field, useField} from "formik";
import {CCheckbox} from "../JobDescription/Openings";

const degreeOptions = [
    {
        value: "Electrical Engg",
        label: "Electrical Engg"
    },
    {
        value: "Electrical & Electronics Engg",
        label: "Electrical & Electronics Engg"
    },
    {
        value: "Electronics & Power Engg",
        label: "Electronics & Power Engg"
    },
    {
        value: "Electronics Engg",
        label: "Electronics Engg"
    },
    {
        value: "Electronics & Comm",
        label: "Electronics & Comm"
    },
    {
        value: "Electronics & Telecomm",
        label: "Electronics & Telecomm"
    },
    {
        value: "B.Com",
        label: "B.Com"
    },
    {
        value: "M.Com",
        label: "M.Com"
    },
    {
        value: "B.B.A.",
        label: "B.B.A."
    },
    {
        value: "M.B.A.",
        label: "M.B.A.",
    },
    {
        value: "B.A.",
        label: "B.A.",
    },
    {
        value: "M.A.",
        label: "M.A."
    },
    {
        value: "Other",
        label: "Other"
    }

]

const qualificationOptions = [
    {
        value: "B.E. / B.Tech"
    },
    {
        value: "M.E. / M.Tech"
    },
    {
        value: "Diploma (Engg)"
    },
    {
        value: "B.Sc. (Engg)"
    },
    {
        value: "M.B.A."
    },
    {
        value: "B.Com"
    },
    {
        value: "M.Com",
        label: "M.Com"
    },
    {
        value: "B.A.",
        label: "B.A.",
    },
    {
        value: "M.A.",
        label: "M.A."
    },
    {
        value: "Other",
        label: "Other"
    }
]

const languages = [
    'English',
    'Hindi',
    'Marathi'
]

const presentAccommodation = [
    {
        value: "Own House"
    },
    {
        value: "With Parents"
    },
    {
        value: "Rented / PG"
    }
]

const yesNo = [
    {
        value: 'Yes'
    },
    {
        value: 'No'
    },
]

function PersonalInfo({jobs, maxs}) {
    const theme = useTheme();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    var dob = yyyy - 18 + '-' + mm + '-' + dd;

    const [, meta, helper] = useField('jobDescription')
    const {value} = meta
    const {setValue} = helper
    const [max, setMax] = useState(0)

    useEffect(()=>{

        jobs.map(
            (option,) => {
                var tmax = max
                value.forEach(e => {
                    if (e === option.jobDescription) {
                        console.log(max)
                        console.log(option)
                        if (option.maxCTC === null && max === 0) {
                            tmax = 5
                            setMax(5)
                            console.log(option)
                        } else if (option.maxCTC > tmax) {
                            console.log(option)
                            tmax = option.maxCTC
                            setMax(tmax)
                        }
                    }
                })
            })
    },[value, meta])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Container>

                    <Typography fontFamily={'"Raleway", sans-serif'} variant={'h6'}
                                sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                        Selected jobs:
                    </Typography>
                    <Box
                        sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 250,
                            maxWidth: '100%',
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}>
                        <Grid container spacing={2}>
                            {jobs.map(
                                (option,) => (
                                    value.includes(option.jobDescription) ?
                                        <Grid item xs={12}>
                                            <Paper elevation={5} sx={{
                                                width: '90%',
                                                color: "black",
                                                backgroundColor: "#FFC300",
                                            }}
                                            onClick={(e)=> {
                                                if(value.length === 1){
                                                    return
                                                }
                                                const index = value.indexOf(option.jobDescription);
                                                if (index > -1) {
                                                    value.splice(index, 1);
                                                }

                                                setValue(value)
                                                var tmax = max
                                                value.forEach(e => {
                                                    if (e === option.jobDescription) {
                                                        console.log(max)
                                                        console.log(option)
                                                        if (option.maxCTC === null && max === 0) {
                                                            tmax = 5
                                                            setMax(5)
                                                            console.log(option)
                                                        } else if (option.maxCTC > tmax) {
                                                            console.log(option)
                                                            tmax = option.maxCTC
                                                            setMax(tmax)
                                                        }
                                                    }
                                                })
                                            }}>
                                                <ButtonBase sx={{textAlign: 'left', width: '100%'}}>

                                                <Container sx={{textAlign: 'left'}}>
                                                    <Grid sx={{padding: 3}}>
                                                        <Grid item xs={12}>
                                                            <Typography fontFamily={'"Raleway", sans-serif'}
                                                                        variant={"h5"}
                                                                        sx={{
                                                                            fontWeight: 'bold',
                                                                            fontFamily: '"Raleway", sans-serif'
                                                                        }}>
                                                                {option.jobDescription}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography fontFamily={'"Raleway", sans-serif'}
                                                                        variant={"p"} sx={{
                                                                marginTop: 1,
                                                                fontFamily: '"Raleway", sans-serif'
                                                            }}>
                                                                <i>Openings</i>: {option.openings}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography fontFamily={'"Raleway", sans-serif'}
                                                                        variant={"p"}
                                                                        sx={{fontFamily: '"Raleway", sans-serif'}}>
                                                                <i>Offer</i>: {option.offer}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography fontFamily={'"Raleway", sans-serif'}
                                                                        fontWeight={'bold'}>
                                                                <i>Remarks</i>: {option.remarks}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                                </ButtonBase>
                                            </Paper>
                                        </Grid> : null
                                )
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Grid>
            <Grid item xs={12} md={6}>
                <Container maxWidth={'md'} sx={{margin: {md: 5}, padding: {xs: 5, md: 0}}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} variant={"h4"}
                                        sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                Personal Information
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginTop: 1.75,
                            marginBottom: 5,
                            background: "#000000"
                        }}/>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required name={"email"} label={"Email"} type={"email"}/>
                        </Grid>

                        <Grid item md={6}/>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required helperText={"Eg. John"} name={"firstName"} label={"First Name"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper helperText={"Eg. Bailey"} name={"middleName"} label={"Middle Name"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required helperText={"Eg. Smith"} name={"lastName"} label={"Last Name"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required name={"experience"} type={"number"} helperText={"Eg. 5"}
                                              label={"Total Work Experience Year(s)"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required name={"location"} label={"Present Location"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required select name={"workStatus"}
                                              label={"Are you presently working somewhere"}>
                                {yesNo.map(
                                    (option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    )
                                )}
                            </TextfieldWrapper>
                        </Grid>

                        <Grid item md={6} xs={12} mt={{md: -3}}>
                            <Typography fontFamily={'"Raleway", sans-serif'}>
                                What date are you available to start work?
                            </Typography>
                            {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                            {/*    <DatePicker*/}
                            {/*        required name={"dateStartWork"} label={" "}*/}
                            {/*        value={()=> {*/}
                            {/*            return new Date()*/}
                            {/*        }}*/}
                            {/*        onChange={(newValue) => {*/}
                            {/*        }}*/}
                            {/*        renderInput={(params) => <TextfieldWrapper {...params} />}>*/}
                            {/*    </DatePicker>*/}
                            {/*</LocalizationProvider>*/}
                            <TextfieldWrapper required name={"dateStartWork"} label={" "} type="date" inputProps={{
                                min: today
                            }}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required select name={"qualification"} label={"Highest Qualification"}>
                                {qualificationOptions.map(
                                    (option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    )
                                )}
                            </TextfieldWrapper>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required select name={"degree"} label={"Course / Stream"}>
                                {degreeOptions.map(
                                    (option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    )
                                )}
                            </TextfieldWrapper>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required helperText={"Only institute/college, not university"}
                                              name={"institute"} label={"Institute / College"}/>
                        </Grid>

                        {/*<Grid item md={6}/>*/}
                        {/*<Grid item md={6}/>*/}

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required helperText={"Eg. 80%"} type={"number"} name={"marks"}
                                              label={"Marks Obtained(%)"}/>
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*    <TextfieldWrapper required name={"project"} label={"Project Title"}/>*/}
                        {/*</Grid>*/}
                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required type={'number'} helperText={"Annual CTC (In Lakhs)"}
                                              name={"salary"}
                                              label={"Expected Salary"} InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <CurrencyRupee/>
                                    </InputAdornment>
                                ),
                                inputProps: { max: max }
                            }}/>

                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container sx={{alignItems: 'center', justifyContent: 'center', marginLeft: -2}}
                                  spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Typography fontFamily={'"Raleway", sans-serif'} sx={{paddingLeft: 2}}>
                                        Date Of Birth*
                                    </Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextfieldWrapper required name={"dob"} type={"date"} label={" "} inputProps={{
                                        max: dob
                                    }}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container
                                  sx={{alignItems: 'center', justifyContent: 'center', marginLeft: {md: 5, xs: 1}}}>
                                <Grid item xs={12}>
                                    <Typography fontFamily={'"Raleway", sans-serif'}> Languages: </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {languages.map(
                                        (val,) => (
                                            <Field as={FormControlLabel}
                                                   control={<CCheckbox sx={{paddingY: 0, marginY: 0, marginX: -1}}/>}
                                                   type={"checkbox"} name={"language"} value={val} label={val}/>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper name={"religion"} label={"Religion"}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper name={"caste"} label={"Caste"}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper type={"number"} name={"numberOfDependents"} label={"No. of dependents"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required select name={"vehicle"} label={"Do you own a vehicle"}>
                                {yesNo.map(
                                    (option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    )
                                )}
                            </TextfieldWrapper>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper select name={"accommodation"} label={"Present accommodation"}>
                                {presentAccommodation.map(
                                    (option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    )
                                )}
                            </TextfieldWrapper>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={6} sx={{
                display: 'block',
                [theme.breakpoints.down('sm')]:
                    {
                        display: 'none'
                    }
            }}>
                <h1 style={{
                    fontFamily: '"Raleway", sans-serif',
                    fontSize: 'calc(1.375rem + 5.4vw)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '80%',
                    marginTop: 150
                }}>"Your <span style={{
                    color: 'rgb(230, 161, 41)'
                }}>future</span> depends on what you do <span style={{
                    color: 'rgb(230, 161, 41)'
                }}>today</span>"</h1>
            </Grid>
        </Grid>
    );
}

export default PersonalInfo;