import React from 'react';
import {Box, ButtonBase, Container, Divider, Grid, Paper, Typography, useTheme} from "@mui/material";
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import {useField} from "formik";

function AboutInfo({jobs}) {
    const theme = useTheme()
    const [, meta, helper] = useField('jobDescription')
    const {value} = meta
    const {setValue} = helper
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Container>

                    <Typography fontFamily={'"Raleway", sans-serif'} variant={'h6'}
                                sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                        Selected jobs:
                    </Typography>
                    <Box
                        sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 250,
                            maxWidth: '100%',
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}>
                        <Grid container spacing={2}>
                            {jobs.map(
                                (option,) => (
                                    value.includes(option.jobDescription) ?
                                        <Grid item xs={12}>
                                            <Paper elevation={5} sx={{
                                                width: '90%',
                                                color: "black",
                                                backgroundColor: "#FFC300",
                                            }}
                                                   onClick={(e) => {
                                                       if(value.length === 1){
                                                           return
                                                       }
                                                       const index = value.indexOf(option.jobDescription);
                                                       if (index > -1) {
                                                           value.splice(index, 1);
                                                       }

                                                       setValue(value)
                                                       console.log(value)
                                                   }}>
                                                <ButtonBase sx={{textAlign: 'left', width: '100%'}}>

                                                    <Container sx={{textAlign: 'left'}}>
                                                        <Grid sx={{padding: 3}}>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"h5"}
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: '"Raleway", sans-serif'
                                                                            }}>
                                                                    {option.jobDescription}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"} sx={{
                                                                    marginTop: 1,
                                                                    fontFamily: '"Raleway", sans-serif'
                                                                }}>
                                                                    <i>Openings</i>: {option.openings}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"}
                                                                            sx={{fontFamily: '"Raleway", sans-serif'}}>
                                                                    <i>Offer</i>: {option.offer}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            fontWeight={'bold'}>
                                                                    <i>Remarks</i>: {option.remarks}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </ButtonBase>
                                            </Paper>
                                        </Grid> : null
                                )
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Grid>
            <Grid item md={6} xs={12}>
                <Container maxWidth={'md'} sx={{margin: {md: 5}, padding: {xs: 5, md: 0} }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' variant={"h4"} sx={{fontWeight:'bold', fontFamily:'"Raleway", sans-serif'}} >
                                About You
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{ width:"100%",borderBottomWidth: 5, marginTop:1.75, marginBottom:5,background:"#000000" }} />
                        <Grid item md={12} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2}>
                                Tell us something about yourself
                            </Typography>
                            <TextfieldWrapper required multiline={true} rows={4} name={"aboutYourself"} label={"Required"}/>
                        </Grid>

                        <Grid item md={6}/>

                        <Grid item md={12} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2}>
                                A brief summary about your final year project or last job profile
                            </Typography>
                            <TextfieldWrapper required multiline={true} rows={4} name={"summary"} label={"Required"}/>
                        </Grid>

                        <Grid item md={6}/>

                        <Grid item md={12} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2}>
                                Who is the most influential or important person in your life and why?
                            </Typography>
                            <TextfieldWrapper multiline={true} rows={4} name={"question_1"} label={" "}/>
                        </Grid>
                        <Grid item md={6}/>

                        <Grid item md={12} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2}>
                                What are your strengths & weaknesses?
                            </Typography>
                            <TextfieldWrapper multiline={true} rows={4} name={"question_2"} label={" "}/>
                        </Grid>

                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={6} sx={{
                display: 'block',
                [theme.breakpoints.down('sm')]:
                    {
                        display: 'none'
                    }
            }}>
                <h1 style={{ fontFamily: '"Raleway", sans-serif', fontSize: 110, marginLeft: 'auto', marginRight: 'auto', width: '90%', marginTop: 150 }}>"Knowing <span style={{
                color: 'rgb(230, 161, 41)' }}>yourself</span> is the beginning of all <span style={{ 
                    color: 'rgb(230, 161, 41)' }}>wisdom</span>"</h1>
            </Grid>
        </Grid>
    );
}

export default AboutInfo;