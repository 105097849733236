import React, {Component} from 'react';
import 'react-step-progress/dist/index.css';
import FormComponent from "../../components/StepSection/FormComponent";

class Applicationform extends Component {
    render() {
        return (
            <div style={{width: '100%'}}>
                <FormComponent/>
            </div>
        );
    }
}

export default Applicationform;