import React from 'react';
import {CButton} from "../Topbar/Topbar";

function FormNavigation(props) {
    return (
        <div style={{
            display: 'flex',
            marginTop: 10,
            marginLeft: 30,
            marginRight: 30,
            justifyContent: 'space-between'
        }}>
            {props.hasPrevious?(<CButton type='button' onClick={props.onBackClick}> Back </CButton>):(<CButton disabled type='button' onClick={props.onBackClick}> Back </CButton>)}
            {props.stepName !== 'Download' && <CButton type={'submit'}>{props.isLastStep ? 'Submit' : 'Next'}</CButton>}
        </div>
    );
}

export default FormNavigation;