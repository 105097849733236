import React from 'react';
import Box from "@mui/material/Box";
import {Container, Grid, Paper, Typography} from "@mui/material";

function Error() {
    return (
        <React.Fragment>
            <React.Fragment>
                <Container component={Box} p={4} sx={{
                    marginY: 15
                }}>
                    <Paper component={Box} p={3}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Container maxWidth={'md'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={'h2'} sx={{textAlign: "center", fontFamily: '"Raleway", sans-serif'}}>
                                                Some error has occurred
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </React.Fragment>
        </React.Fragment>
    );
}

export default Error;