import React, {useState} from 'react';
import {Form, Formik} from "formik";
import FormNavigation from "./FormNavigation";
import Stepper from "@mui/material/Stepper";
import {IconButton} from "@mui/material";
import Step from "@mui/material/Step";
import {
    Badge,
    BadgeOutlined,
    Check,
    ContactPhone,
    ContactPhoneOutlined,
    Description,
    DescriptionOutlined,
    Info,
    InfoOutlined,
    Person,
    PersonOutlined,
    Policy,
    PolicyOutlined,
    School,
    SchoolOutlined
} from "@mui/icons-material";


const stepsFilledIcon = [null, <Info sx={{color: '#FFC300', fontSize: 40}}/>,
    <Person sx={{color: '#FFC300', fontSize: 40}}/>,
    <ContactPhone sx={{color: '#FFC300', fontSize: 40}}/>, <Description sx={{color: '#FFC300', fontSize: 40}}/>,
    <Badge sx={{color: '#FFC300', fontSize: 40}}/>,
    <School sx={{color: '#FFC300', fontSize: 40}}/>, <Policy sx={{color: '#FFC300', fontSize: 40}}/>]
const stepsOutlinedIcon = [null, <InfoOutlined sx={{color: '#FFC300', fontSize: 40}}/>,
    <PersonOutlined sx={{color: '#FFC300', fontSize: 40}}/>,
    <ContactPhoneOutlined sx={{color: '#FFC300', fontSize: 40}}/>,
    <DescriptionOutlined sx={{color: '#FFC300', fontSize: 40}}/>,
    <BadgeOutlined sx={{color: '#FFC300', fontSize: 40}}/>, <SchoolOutlined sx={{color: '#FFC300', fontSize: 40}}/>,
    <PolicyOutlined sx={{color: '#FFC300', fontSize: 40}}/>]
const completeStepIcon = <Check sx={{color: '#4caf50', fontSize: 40}}/>

function MultiStepForm({children, initialValue, onSubmit}) {
    const [stepNumber, setStepNumber] = useState(0);
    const [completed, setCompleted] = useState({});
    const [activeStepIcon, setActiveStepIcon] = useState(stepsFilledIcon[0])
    const steps = React.Children.toArray(children);

    const [, setSnapshot] = useState(initialValue);

    const next = (values) => {
        const newCompleted = completed;
        newCompleted[stepNumber] = true;
        setCompleted(newCompleted);
        setSnapshot(values)
        setActiveStepIcon(stepsFilledIcon[stepNumber + 1])
        setStepNumber(prevState => prevState + 1)
    }

    const step = steps[stepNumber]
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1

    const previous = (values) => {
        setSnapshot(values)
        setStepNumber(prevState => prevState - 1)
        setActiveStepIcon(stepsFilledIcon[stepNumber - 1])
    }

    const handleSubmit = async (values, actions) => {
        if (step.props.onSubmit) {
            await step.props.onSubmit(values)
        }

        if (isLastStep) {
            return onSubmit(values)
        } else {
            actions.setTouched({})
            next(values)
        }
    }

    return (
        <div>
            <Formik initialValues={step.props.initialValues ? step.props.initialValues : {}} onSubmit={handleSubmit}
                    validationSchema={step.props.validationSchema}>
                <Form>

                    {
                        step.props.stepName === 'Download' || step.props.stepName === 'Job Description' ?
                            null
                            :
                            <Stepper activeStep={stepNumber} sx={{
                                marginBottom: 3,
                                marginTop: 12,
                                marginX: {
                                    xs: -1,
                                    md: 20
                                },
                                paddingX:{
                                    xs: -15
                                },
                                paddingTop: 4
                            }}>
                                {steps.map((currentStep, index) => {
                                    return (
                                        index === 0 ?
                                            null :
                                            <Step key={index} completed={completed[index]} sx={{
                                                padding: 0
                                            }}>

                                                <IconButton>
                                                    {index === stepNumber ? activeStepIcon : completed[index] ? completeStepIcon : stepsOutlinedIcon[index]}
                                                </IconButton>
                                            </Step>


                                    )
                                })}
                            </Stepper>
                    }
                    {step}
                    {step.props.stepName !== 'Download' &&
                        <FormNavigation isLastStep={isLastStep} hasPrevious={stepNumber > 0}
                                        onBackClick={() => previous(Formik.values)}/>}
                </Form>
            </Formik>
        </div>
    );
}

export default MultiStepForm;

export const FormStep = ({values, children}) => children;