import React, {useState} from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    ButtonBase,
    Container,
    Divider, FormControl,
    FormControlLabel, FormLabel,
    Grid,
    Paper, Radio, RadioGroup, Stack,
    Typography,
    useTheme
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import {Field, useField} from "formik";
import {CCheckbox} from "../JobDescription/Openings";

const itAndPresentationOptions = [
    "Competent in handling all online meeting platforms such as Zoom, MS Team in workplace",
    "Tally",
    "Basic Office (MS - Word, MS - Excel, MS - Outlook)",
    "Expert Powerpoint & Presentation Skills",
    "Google calendar",
    "CAD Software",
    "BIM/Revit"
]

const digitalSkills = [
    'MS Teams in workplace',
    'MS - Word',
    'MS – Excle',
    'MS – Outlook',
    'MS – Powerpoint',
    'Autocad',
    'BIM / Revit',
    'MS – Projects',
    'Tally'
]

function EducationInfo({jobs}) {
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [, meta, helper] = useField('jobDescription')
    const {value} = meta
    const {setValue} = helper

    const [itField, ,] = useField('itAndPresentation')
    const [dSkills, ,] = useField('digitalSkills')

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Container>

                    <Typography fontFamily={'"Raleway", sans-serif'} variant={'h6'}
                                sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                        Selected jobs:
                    </Typography>
                    <Box
                        sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 250,
                            maxWidth: '100%',
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}>
                        <Grid container spacing={2}>
                            {jobs.map(
                                (option,) => (
                                    value.includes(option.jobDescription) ?
                                        <Grid item xs={12}>
                                            <Paper elevation={5} sx={{
                                                width: '90%',
                                                color: "black",
                                                backgroundColor: "#FFC300",
                                            }}
                                                   onClick={(e) => {
                                                       const index = value.indexOf(option.jobDescription);
                                                       if (index > -1) {
                                                           value.splice(index, 1);
                                                       }

                                                       setValue(value)
                                                       console.log(value)
                                                   }}>
                                                <ButtonBase sx={{textAlign: 'left', width: '100%'}}>

                                                    <Container sx={{textAlign: 'left'}}>
                                                        <Grid sx={{padding: 3}}>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"h5"}
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: '"Raleway", sans-serif'
                                                                            }}>
                                                                    {option.jobDescription}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"} sx={{
                                                                    marginTop: 1,
                                                                    fontFamily: '"Raleway", sans-serif'
                                                                }}>
                                                                    <i>Openings</i>: {option.openings}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"}
                                                                            sx={{fontFamily: '"Raleway", sans-serif'}}>
                                                                    <i>Offer</i>: {option.offer}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            fontWeight={'bold'}>
                                                                    <i>Remarks</i>: {option.remarks}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </ButtonBase>
                                            </Paper>
                                        </Grid> : null
                                )
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Grid>
            <Grid item md={6} xs={12}>
                <Container maxWidth={'md'} sx={{margin: {md: 5}, padding: {xs: 5, md: 0}}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' variant={"h4"} sx={{fontWeight: 'bold'}}>
                                Education Information
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginTop: 1.75,
                            marginBottom: 5,
                            background: "#000000"
                        }}/>
                        <Grid item md={6} xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' sx={{marginBottom: 1}}>
                                SSC / X
                            </Typography>
                            <TextfieldWrapper required name={"sscXInstitute"} label={"SSC / X Institute"}
                                              helperText={"Eg. Modern School Nagpur"}/>
                        </Grid>

                        <Grid item md={6} xs={12} mt={{md: 4}}>
                            <TextfieldWrapper required name={"sscXBoard"} label={"SSC / X Board"}
                                              helperText={"Eg. Maharashtra State Board"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required name={"sscXYear"} type={"number"} label={"SSC / X Year"}
                                              helperText={"Eg. 2016"}/>
                        </Grid>


                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required name={"sscXCity"} label={"SSC / X City"}
                                              helperText={"Eg. Nagpur"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextfieldWrapper required name={"sscXMarks"} type={"number"} label={"SSC / X Marks(%)"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion xpanded={expanded === 'Diploma'} onChange={handleChange('diploma')} sx={{
                                width: '100%',
                                borderColor: '#FFC300',
                                borderWidth: '10%'
                            }} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="diplomabh-content"
                                    id="diplomabh-header">
                                    <Typography fontFamily='"Raleway", sans-serif' sx={{width: '100%', flexShrink: 0}}>
                                        Have you done 12th?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <Typography fontFamily='"Raleway", sans-serif' sx={{marginBottom: 1}}>
                                                HSC / XII
                                            </Typography>
                                            <TextfieldWrapper name={"hscXIIInstitute"}
                                                              label={"HSC / XII Institute"}
                                                              helperText={"Eg. Hislop Science College"}/>
                                        </Grid>

                                        <Grid item md={6} mt={{md: 4}} xs={12}>
                                            <TextfieldWrapper name={"hscXIIBoard"} label={"HSC / XII Board"}
                                                              helperText={"Eg. Maharashtra state board"}/>
                                        </Grid>


                                        <Grid item md={4} xs={12}>
                                            <TextfieldWrapper type={"number"} name={"hscXIIYear"}
                                                              label={"HSC / XII Year"}
                                                              helperText={"Eg. 2018"}/>
                                        </Grid>


                                        <Grid item md={4} xs={12}>
                                            <TextfieldWrapper name={"hscXIICity"} label={"HSC / XII City"}
                                                              helperText={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <TextfieldWrapper type={"number"} name={"hscXIIMarks"}
                                                              label={"HSC / XII Marks(%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion xpanded={expanded === 'Diploma'} onChange={handleChange('diploma')} sx={{
                                width: '100%',
                                borderColor: '#FFC300',
                                borderWidth: '10%'
                            }} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="diplomabh-content"
                                    id="diplomabh-header">
                                    <Typography fontFamily='"Raleway", sans-serif' sx={{width: '100%', flexShrink: 0}}>
                                        Have you done diploma?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"diplomaInstitute"}
                                                              label={"Diploma Institute"}
                                                              helperText={"Eg. MSBTE"}/>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"diplomaStream"}
                                                              label={"Diploma Stream"}
                                                              helperText={"Eg. Electrical Engg"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} type={"number"} name={"diplomaYear"}
                                                              label={"Diploma year"}
                                                              helperText={"Eg. 2017"}/>
                                        </Grid>


                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"diplomaCity"}
                                                              label={"Diploma City"}
                                                              helperText={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} type={"number"} name={"diplomaMarks"}
                                                              label={"Diploma Marks (%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography fontFamily='"Raleway", sans-serif' sx={{marginBottom: 1}}>
                                Graduation Degree
                            </Typography>
                            <TextfieldWrapper required name={"gradInstitute"} label={"Graduation Institute"}
                                              helperText={"Eg RKNEC Nagpur, RTMNU"}/>
                        </Grid>

                        <Grid item xs={12} md={6} mt={{md: 4}}>
                            <TextfieldWrapper required name={"gradStream"} label={"Graduation Stream"}
                                              helperText={"Eg. Electrical Engg"}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper required name={"gradYear"} type={"number"} label={"Graduation Year"}
                                              helperText={"Eg. 2021"}/>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper required name={"gradCity"} label={"Graduation City"}
                                              helperText={"Eg. Nagpur"}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper required name={"gradMarks"} type={"number"}
                                              label={"Graduation Marks (%)"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion sx={{width: '100%'}} xpanded={expanded === 'postgrad'}
                                       onChange={handleChange('postgrad')} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="postgradbh-content"
                                    id="postgradbh-header">
                                    <Typography fontFamily='"Raleway", sans-serif' sx={{
                                        width: '100%',
                                        flexShrink: 0
                                    }}>
                                        Have you done post graduation?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"postGradInstitute"}
                                                              label={"Post Graduation Institute"}
                                                              helpertext={"Eg. RKNEC Nagpur, RTMNU"}/>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"postGradStream"}
                                                              label={"Post Graduation Stream"}
                                                              helpertext={"Eg. 2022"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} type={"number"}
                                                              name={"postGradYear"}
                                                              label={"Post Graduation Year"}
                                                              helpertext={"Eg. PE & PS"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"postGradCity"}
                                                              label={"Post Graduation City"}
                                                              helpertext={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextfieldWrapper sx={{width: '100%'}} name={"postGradMarks"}
                                                              type={"number"} label={"Post Graduation Marks (%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextfieldWrapper required name={"project"} label={"Project Title"}/>
                        </Grid>
                        <Grid item md={6}/>

                        <Grid item xs={12} md={6}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={1} marginTop={1}>
                                Skills
                            </Typography>
                            <TextfieldWrapper required name={"skills"} label={"Skills"}
                                              helperText={"Eg. Skills, Courses, Trainings, Awards"}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={1} marginTop={1}>
                                Achievements
                            </Typography>
                            <TextfieldWrapper name={"achievements"} label={"Achievements"}
                                              helperText={"Eg. Awards"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginTop={3} marginBottom={1}>
                                IT & Presentation Skills (Tick whichever is applicable)
                            </Typography>
                        </Grid>

                        {itAndPresentationOptions.map(
                            (val,) => (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Field required as={FormControlLabel}
                                                   control={<CCheckbox sx={{paddingY: 0, marginY: 0}}/>}
                                                   type={"checkbox"}
                                                   name={"itAndPresentation"} value={val} label={val}/>
                                        </Grid>
                                        {itField.value.map(
                                            (v, i) => {
                                                if (v === val) {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <FormControl>
                                                                <FormLabel id="demo-controlled-radio-buttons-group">Level</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="1"
                                                                    name="radio-buttons-group">
                                                                    <Stack direction={'row'} ml={4}>

                                                                        <FormControlLabel value="1"
                                                                                          control={<Radio sx={{
                                                                                              color: '#FFC300',
                                                                                              '&.Mui-checked': {
                                                                                                  color: '#FFC300',
                                                                                              },
                                                                                          }}/>} label="1"/>
                                                                        <FormControlLabel value="2"
                                                                                          control={<Radio sx={{
                                                                                              color: '#FFC300',
                                                                                              '&.Mui-checked': {
                                                                                                  color: '#FFC300',
                                                                                              },
                                                                                          }}/>}
                                                                                          label="2"/>
                                                                        <FormControlLabel value="3"
                                                                                          control={<Radio sx={{
                                                                                              color: '#FFC300',
                                                                                              '&.Mui-checked': {
                                                                                                  color: '#FFC300',
                                                                                              },
                                                                                          }}/>}
                                                                                          label="3"/>
                                                                        <FormControlLabel value="4"
                                                                                          control={<Radio sx={{
                                                                                              color: '#FFC300',
                                                                                              '&.Mui-checked': {
                                                                                                  color: '#FFC300',
                                                                                              },
                                                                                          }}/>}
                                                                                          label="4"/>
                                                                        <FormControlLabel value="5"
                                                                                          control={<Radio sx={{
                                                                                              color: '#FFC300',
                                                                                              '&.Mui-checked': {
                                                                                                  color: '#FFC300',
                                                                                              },
                                                                                          }}/>}
                                                                                          label="5"/>
                                                                    </Stack>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                }
                                            }
                                        )}
                                    </Grid>

                                </Grid>
                            )
                        )}

                        <Grid item xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' marginTop={3} marginBottom={1}>
                                Digital Skills
                            </Typography>
                        </Grid>

                        {digitalSkills.map(
                            (val,) => (
                                <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                    <Field as={FormControlLabel}
                                           control={<CCheckbox sx={{paddingY: 0, marginY: 0}}/>} type={"checkbox"}
                                           name={"digitalSkills"} value={val} label={val}/>
                                    </Grid>
                                    {dSkills.value.map(
                                        (v, i) => {
                                            if (v === val) {
                                                return (
                                                    <Grid item xs={12}>
                                                        <FormControl>
                                                            <FormLabel id="demo-controlled-radio-buttons-group">Level</FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="1"
                                                                name="radio-buttons-group">
                                                                <Stack direction={'row'} ml={4}>

                                                                    <FormControlLabel value="1"
                                                                                      control={<Radio sx={{
                                                                                          color: '#FFC300',
                                                                                          '&.Mui-checked': {
                                                                                              color: '#FFC300',
                                                                                          },
                                                                                      }}/>} label="1"/>
                                                                    <FormControlLabel value="2"
                                                                                      control={<Radio sx={{
                                                                                          color: '#FFC300',
                                                                                          '&.Mui-checked': {
                                                                                              color: '#FFC300',
                                                                                          },
                                                                                      }}/>}
                                                                                      label="2"/>
                                                                    <FormControlLabel value="3"
                                                                                      control={<Radio sx={{
                                                                                          color: '#FFC300',
                                                                                          '&.Mui-checked': {
                                                                                              color: '#FFC300',
                                                                                          },
                                                                                      }}/>}
                                                                                      label="3"/>
                                                                    <FormControlLabel value="4"
                                                                                      control={<Radio sx={{
                                                                                          color: '#FFC300',
                                                                                          '&.Mui-checked': {
                                                                                              color: '#FFC300',
                                                                                          },
                                                                                      }}/>}
                                                                                      label="4"/>
                                                                    <FormControlLabel value="5"
                                                                                      control={<Radio sx={{
                                                                                          color: '#FFC300',
                                                                                          '&.Mui-checked': {
                                                                                              color: '#FFC300',
                                                                                          },
                                                                                      }}/>}
                                                                                      label="5"/>
                                                                </Stack>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                )
                                            }

                                            return null
                                        }
                                    )}
                                </Grid>
                            )
                        )}


                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={6} sx={{
                display: 'block',
                [theme.breakpoints.down('sm')]:
                    {
                        display: 'none'
                    }
            }}>
                <h1 style={{
                    fontFamily: '"Raleway", sans-serif',
                    fontSize: 110,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '80%',
                    marginTop: 180
                }}>"A good <span style={{
                    color: 'rgb(230, 161, 41)'
                }}>education</span> is the <span style={{
                    color: 'rgb(230, 161, 41)'
                }}>foundation</span> of a better <span style={{
                    color: 'rgb(230, 161, 41)'
                }}>future</span>"</h1>
            </Grid>
        </Grid>
    );
}

export default EducationInfo;