import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Box, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import civil from "./civil.jpg";
import './jd.css';

const styles = {
    header: {
        backgroundImage: `url(${civil})`,
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        left: 0,
        right: 0,
        top: 0
    },

    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        fontFamily: '"Cabin", Sans-serif',
        margin: '0 !important'
    }
}

function JobDescription() {

    return (
        <div className="mt-5">
            <main>

                <div style={styles.header}>
                    <div style={styles.content}>
                        <div className='middle-div'>
                            <h3 style={{
                                color: 'rgb(230, 161, 41)',
                                fontSize: 'calc(2rem + .6vw)',
                                fontWeight: 700
                            }}>WELCOME TO TECHNODEAL GROUP</h3>
                            <h1 style={{color: 'white', fontSize: 'calc(1rem + .6vw)'}}>Building Engineering
                                Services</h1>
                            <h2 style={{color: 'white', fontSize: 'calc(1rem + .6vw)'}}>Engineering and Contracting
                                Group</h2>
                            <br/><br/>
                            <h2 style={{
                                color: 'rgb(230, 161, 41)',
                                fontSize: 'calc(2rem + .6vw)',
                                fontWeight: 700
                            }}>Established in 1985 </h2>
                            <h2 style={{color: 'white', fontSize: 'calc(1rem + .6vw)'}}>Projects, anywhere in India</h2>
                            <br/><br/>
                            <a target="_blank" href="https://bit.ly/tepllinked"><i className="fa fa-linkedin-square"
                                                                                   style={{fontSize: 'calc(1.5rem + 1.6vw)'}}></i></a>
                            <a target="_blank" href="https://www.technodeal.co.in"><i className="fa fa-info-circle"
                                                                                      style={{
                                                                                          fontSize: 'calc(1.5rem + 1.6vw)',
                                                                                          marginLeft: 20
                                                                                      }}></i></a>

                        </div>
                    </div>
                </div>

                <div style={{backgroundColor: 'rgb(240, 201, 51)', paddingTop: 40, paddingBottom: 65}}>
                    <h2 style={{
                        fontFamily: 'Raleway,Sans-serif',
                        fontSize: 'calc(1.17rem + 1.8vw)',
                        textAlign: 'center',
                        color: 'black'
                    }}>Recruitment
                        Information</h2>
                    <br/>

                    <p style={{
                        fontFamily: '"Raleway", sans-serif',
                        marginLeft: '10%',
                        marginRight: '10%',
                        fontSize: 'calc(.5rem + .8vw)',
                        textAlign: 'justify',
                        color: 'black'
                    }}>
                        We are looking for versatile, dynamic and responsible engineers, management professionals,
                        trainees
                        and interns to drive company projects and/or provide key interventions to TEPL‘s senior
                        management,
                        to support the management in TEPL‘s aggressive growth story.

                        At TEPL, the candidate shall be expected to take up roles in engineering, projects, operations,
                        finance, administration and leadership, depending on the candidates' profiles and application,
                        as
                        TEPL expands its geographical footprints in both the domestic and international markets while it
                        also grows its new verticals into full-fledged businesses.

                        The candidate shall need to have good observational and analytical skills along with a keen
                        sense of
                        the practicalities of site and/or business awareness. Engineering knowledge shall be considered
                        a
                        plus. The candidate needs to be open to both office and on-site assignments as per the job role
                        and
                        be ready to try and learn the latest technologies and systems for effective implementation of
                        new
                        MIS systems.

                        The end goals are to meet and surpass the company’s expectations to drive rapid, scalable and
                        sustainable growth whilst maintaining core organisational values and a positive client impact.
                    </p>
                </div>

                <p style={{
                    fontFamily: '"Raleway", sans-serif',
                    color: 'black',
                    fontSize: 'calc(.4rem + .8vw)',
                    marginTop: 60,
                    marginRight: '8.16vw',
                    marginLeft: '8.16vw',
                    textAlign: 'center',
                }}>
                    Candidate needs to be open to both office and on-site assignments, ready to travel for project
                    reviews and ready to learn new technologies.
                </p>
                <div>
                    <h2 style={{
                        fontFamily: '"Raleway", sans-serif',
                        fontSize: 'calc(1.4rem + 1.4vw)',
                        textAlign: 'center',
                        marginTop: 60,
                        marginBottom: 40
                    }}>Please note</h2>

                    <Grid container spacing={5}>
                        <Grid item xs={12} container sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <Container>
                                <Stack direction={'row'} spacing={5} paddingX={2}>
                                    <Paper variant="outlined" style={{
                                        padding: 25,
                                        width: '50%',
                                        textAlign: 'center',
                                        borderColor: 'rgb(230, 161, 41)'
                                    }}>
                                        <Typography sx={{
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: 16
                                        }}>
                                            Mandatory orientation & training for 3 months shall be involved for all
                                            candidates
                                        </Typography>

                                    </Paper>

                                    <Paper variant="outlined" style={{
                                        padding: 25,
                                        maxWidth: '50%',
                                        textAlign: 'center',
                                        borderColor: 'rgb(230, 161, 41)'

                                    }}>
                                        <Typography sx={{
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: 16
                                        }}>
                                            For candidates that we find as promising, we would want to offer a CTC on
                                            the higher
                                            side
                                            but
                                            will require a bond with a commitment of 2 years valued at ₹ 1 Lakhs *
                                        </Typography>

                                    </Paper>
                                </Stack>
                            </Container>
                        </Grid>


                        <Grid item xs={12} container sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <Container>

                                <Stack direction={'row'} spacing={5} paddingX={2}>
                                    <Paper variant="outlined" style={{
                                        padding: 25,
                                        width: '50%',
                                        textAlign: 'center',
                                        borderColor: 'rgb(230, 161, 41)'
                                    }}>
                                        <Typography sx={{
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: 16
                                        }}>
                                            Pay for Freshers during training: Only stipend shall be paid
                                        </Typography>

                                    </Paper>

                                    <Paper variant="outlined" style={{
                                        padding: 25,
                                        maxWidth: '50%',
                                        textAlign: 'center',
                                        borderColor: 'rgb(230, 161, 41)'
                                    }}>
                                        <Typography sx={{
                                            fontFamily: '"Raleway", sans-serif',
                                            fontSize: 16
                                        }}>
                                            Pay for Experienced Candidates: Complete salary shall be paid as per
                                            finalized
                                            CTC
                                        </Typography>

                                    </Paper>
                                </Stack>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
            </main>
        </div>
    );
}

export default JobDescription;