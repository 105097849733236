import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {Field, useField} from "formik";
import axios from "axios";
import {CurrencyRupee} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CCheckbox} from "../JobDescription/Openings";
import {useHistory} from "react-router-dom";

function Agreement({formValues}) {
    const [, meta,] = useField('jobDescription')
    const {value} = meta
    const history = useHistory()
    const [jobs, setJobs] = useState([])
    const [state, setState] = useState([])

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchJobs = async () => {
        const response = await axios.get('https://job-application-services.herokuapp.com/api/v1/get/jobs').catch((err) => console.log(err))
        setJobs(response.data)
        for (let i = 0; i < formValues.noOfWE; ++i) {
            state.push(i)
        }
        setState(state)

    }

    useEffect(() => {
        fetchJobs().catch((err)=> history.push('/error'))
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth={'md'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} variant={"h4"} sx={{fontWeight: 'bold', fontFamily: 'Poppins'}}>
                                Verify
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginTop: 1.75,
                            marginBottom: 1.75,
                            background: "#000000"
                        }}/>


                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.email} label={"Email"}/>
                        </Grid>

                        <Grid item md={6}/>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled helperText={"Eg. John"} value={formValues.firstName}
                                       label={"First Name"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled helperText={"Eg. Bailey"} value={formValues.middleName}
                                       label={"Middle Name"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled helperText={"Eg. Smith"} value={formValues.lastName}
                                       label={"Last Name"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.experience} helperText={"Eg. 5"}
                                       label={"Total Work Experience Year(s)"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.location} label={"Present Location"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.workStatus}
                                       label={"Are you presently working somewhere"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.dateStartWork}
                                       label={"What date are you available to start work?"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.qualification}
                                       label={"Highest Qualification"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.degree} label={"Course / Stream"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled helperText={"Only institute/college, not university"}
                                       value={formValues.institute} label={"Institute / College"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled helperText={"Eg. 80"} value={formValues.marks}
                                       label={"Marks Obtained"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled helperText={"Annual CTC (In Lakhs)"} value={formValues.salary}
                                       label={"Expected Salary"} InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <CurrencyRupee/>
                                    </InputAdornment>
                                )
                            }}/>

                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container sx={{alignItems: 'center', justifyContent: 'center', marginLeft: -2}}
                                  spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Typography fontFamily={'"Raleway", sans-serif'} sx={{paddingLeft: 2}}>
                                        Date Of Birth *
                                    </Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextField fullWidth disabled value={formValues.dob} type={"date"} label={" "}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Grid container sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <Grid item xs={12}>
                                    <TextField fullWidth disabled value={formValues.language} label={'Languages'}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.religion} label={"Religion"}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.caste} label={"Caste"}/>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.numberOfDependents}
                                       label={"No. of dependents"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.vehicle} label={"Do you own a vehicle"}/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.accommodation}
                                       label={"Present accommodation"}/>
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2}>
                                Current Address
                            </Typography>
                            <TextField fullWidth disabled value={formValues.currentAddress} label={"Current Address"}
                                       multiline={true} rows={4}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1.5} mt={{md: 3.5}}>
                                <Grid item xs={12}>
                                    <TextField fullWidth disabled value={formValues.currentPincode}
                                               label={"Current Address Pincode"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth disabled value={formValues.phoneNo} label={"Phone Number"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2} marginTop={2}>
                                Permanent Address
                            </Typography>
                            <TextField fullWidth disabled value={formValues.permanentAddress}
                                       label={"Permanent Address"}
                                       multiline={true} rows={4}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1.5} mt={{md: 5.5}}>
                                <Grid item xs={12}>
                                    <TextField fullWidth disabled value={formValues.permanentPincode}
                                               label={"Permanent Address Pincode"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth disabled value={formValues.emergencyContact}
                                               label={"Emergency Contact Number"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth disabled value={formValues.districtState}
                                       helperText={"Eg. Nagpur, Maharashtra"}
                                       label={"District & State"}/>
                        </Grid>
                        <Grid item md={6}/>


                        <Grid item md={5} xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2}>
                                Tell us something about yourself
                            </Typography>
                            <TextField fullWidth disabled multiline={true} rows={4} value={formValues.aboutYourself}
                                       label={" "}/>
                        </Grid>

                        <Grid item md={7} xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2}>
                                A brief summary about your final year project or last job profile
                            </Typography>
                            <TextField fullWidth disabled multiline={true} rows={4} value={formValues.summary}
                                       label={" "}/>
                        </Grid>

                        <Grid item md={5} xs={12} mt={{md: 3}}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2}>
                                What are your strengths & weaknesses?
                            </Typography>
                            <TextField fullWidth disabled multiline={true} rows={4} value={formValues.question_2}
                                       label={" "}/>
                        </Grid>

                        <Grid item md={7} xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={2}>
                                Who is the most influential or important person in your life and why?
                            </Typography>
                            <TextField fullWidth disabled multiline={true} rows={4} value={formValues.question_1}
                                       label={" "}/>
                        </Grid>

                        {state.map(
                            (_, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={10} marginTop={index === 0 ? 2 : 5} marginBottom={2}>
                                        <Typography fontFamily={'"Raleway", sans-serif'} variant={"h5"}>
                                            Work Experience #{index + 1}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled label={"Previous Position"}
                                                   value={formValues.employmentHistory[index].currentOrLastPosition}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled label={"Name Of Employer"}
                                                   value={formValues.employmentHistory[index].nameOfEmployer}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled
                                                   value={formValues.employmentHistory[index].address}
                                                   label="Address"
                                                   multiline={true} rows={4}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled
                                                   value={formValues.employmentHistory[index].jobDuration}
                                                   label="Job Duration"
                                                   helperText="Eg. [ Jun 2016 - Mar 2019 ]   or   [ Jun 2016 - Till Date ]"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled
                                                   value={formValues.employmentHistory[index].reportingManager}
                                                   label="Reporting Manager"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled
                                                   value={formValues.employmentHistory[index].reasonForLeaving}
                                                   label={"Reason for leaving"}/>
                                    </Grid>
                                </React.Fragment>
                            )
                        )}


                        <Grid item md={6} xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} sx={{marginBottom: 1}}>
                                SSC / X
                            </Typography>
                            <TextField fullWidth disabled value={formValues.sscXInstitute} label={"SSC / X Institute"}
                                       helperText={"Eg. Modern School Nagpur"}/>
                        </Grid>

                        <Grid item md={6} xs={12} mt={{md: 4}}>
                            <TextField fullWidth disabled value={formValues.sscXBoard} label={"SSC / X Board"}
                                       helperText={"Eg. Maharashtra State Board"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.sscXYear} label={"SSC / X Year"}
                                       helperText={"Eg. 2016"}/>
                        </Grid>


                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.sscXCity} label={"SSC / X City"}
                                       helperText={"Eg. Nagpur"}/>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField fullWidth disabled value={formValues.sscXMarks} label={"SSC / X Marks(%)"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion xpanded={expanded === 'Diploma'} onChange={handleChange('diploma')} sx={{
                                width: '100%',
                                borderColor: '#FFC300',
                                borderWidth: '10%'
                            }} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="diplomabh-content"
                                    id="diplomabh-header">
                                    <Typography fontFamily={'"Raleway", sans-serif'} sx={{width: '100%', flexShrink: 0}}>
                                        Have you done 12th?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <Typography fontFamily={'"Raleway", sans-serif'} sx={{marginBottom: 1}}>
                                                HSC / XII
                                            </Typography>
                                            <TextField fullWidth disabled value={formValues.hscXIIInstitute}
                                                       label={"HSC / XII Institute"}
                                                       helperText={"Eg. Hislop Science College"}/>
                                        </Grid>

                                        <Grid item md={6} mt={{md: 4}} xs={12}>
                                            <TextField fullWidth disabled value={formValues.hscXIIBoard}
                                                       label={"HSC / XII Board"}
                                                       helperText={"Eg. Maharashtra state board"}/>
                                        </Grid>


                                        <Grid item md={4} xs={12}>
                                            <TextField fullWidth disabled value={formValues.hscXIIYear}
                                                       label={"HSC / XII Year"}
                                                       helperText={"Eg. 2018"}/>
                                        </Grid>


                                        <Grid item md={4} xs={12}>
                                            <TextField fullWidth disabled value={formValues.hscXIICity}
                                                       label={"HSC / XII City"}
                                                       helperText={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item md={4} xs={12}>
                                            <TextField fullWidth disabled value={formValues.hscXIIMarks}
                                                       label={"HSC / XII Marks(%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion xpanded={expanded === 'Diploma'} onChange={handleChange('diploma')} sx={{
                                width: '100%',
                                borderColor: '#FFC300',
                                borderWidth: '10%'
                            }} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="diplomabh-content"
                                    id="diplomabh-header">
                                    <Typography fontFamily={'"Raleway", sans-serif'} sx={{width: '100%', flexShrink: 0}}>
                                        Have you done diploma?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.diplomaInstitute}
                                                       label={"Diploma Institute"}
                                                       helperText={"Eg. MSBTE"}/>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.diplomaStream}
                                                       label={"Diploma Stream"}
                                                       helperText={"Eg. Electrical Engg"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.diplomaYear}
                                                       label={"Diploma year"}
                                                       helperText={"Eg. 2017"}/>
                                        </Grid>


                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.diplomaCity}
                                                       label={"Diploma City"}
                                                       helperText={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.diplomaMarks}
                                                       label={"Diploma Marks (%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography fontFamily={'"Raleway", sans-serif'} sx={{marginBottom: 1}}>
                                Graduation Degree
                            </Typography>
                            <TextField fullWidth disabled value={formValues.gradInstitute}
                                       label={"Graduation Institute"}
                                       helperText={"Eg RKNEC Nagpur, RTMNU"}/>
                        </Grid>

                        <Grid item xs={12} md={6} mt={{md: 4}}>
                            <TextField fullWidth disabled value={formValues.gradStream} label={"Graduation Stream"}
                                       helperText={"Eg. Electrical Engg"}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField fullWidth disabled value={formValues.gradYear} label={"Graduation Year"}
                                       helperText={"Eg. 2021"}/>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextField fullWidth disabled value={formValues.gradCity} label={"Graduation City"}
                                       helperText={"Eg. Nagpur"}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField fullWidth disabled value={formValues.gradMarks} label={"Graduation Marks (%)"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion sx={{width: '100%'}} xpanded={expanded === 'postgrad'}
                                       onChange={handleChange('postgrad')} elevation={5}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="postgradbh-content"
                                    id="postgradbh-header">
                                    <Typography fontFamily={'"Raleway", sans-serif'} sx={{
                                        width: '100%',
                                        flexShrink: 0
                                    }}>
                                        Have you done post graduation?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.postGradInstitute}
                                                       label={"Post Graduation Institute"}
                                                       helpertext={"Eg. RKNEC Nagpur, RTMNU"}/>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.postGradStream}
                                                       label={"Post Graduation Stream"}
                                                       helpertext={"Eg. 2022"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.postGradYear}
                                                       label={"Post Graduation Year"}
                                                       helpertext={"Eg. PE & PS"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.postGradCity}
                                                       label={"Post Graduation City"}
                                                       helpertext={"Eg. Nagpur"}/>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField fullWidth disabled sx={{width: '100%'}}
                                                       value={formValues.postGradMarks}
                                                       label={"Post Graduation Marks (%)"}/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField fullWidth disabled value={formValues.project} label={"Project Title"}/>
                        </Grid>
                        <Grid item md={6}/>

                        <Grid item xs={12} md={6}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginBottom={1} marginTop={1} >
                                Skills & Achievements
                            </Typography>
                            <TextField fullWidth disabled value={formValues.skillsAchievements}
                                       label={"Skills & Achievements"}
                                       helperText={"Eh. Skills, Courses, Trainings, Awards"}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} marginTop={3} marginBottom={1} sx={{fontFamily: '"Raleway", sans-serif'}}>
                                IT & Presentation Skills (Tick whichever is applicable)
                            </Typography>
                            <TextField fullWidth disabled value={formValues.itAndPresentation}
                                       label={"It & Presentation"}
                                       helperText={"Eh. Skills, Courses, Trainings, Awards"}/>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography fontFamily={'"Raleway", sans-serif'} variant={'h6'} sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                Selected jobs:
                            </Typography>
                            <Box
                                sx={{
                                    mb: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: '100vh',
                                    maxWidth: '100%',
                                    overflow: "hidden",
                                    overflowY: "scroll",
                                }}>
                                <Grid container spacing={2}>
                                    {jobs.map(
                                        (option,) => (
                                            value.includes(option.jobDescription) ?
                                                <Grid item xs={12}>
                                                    <Paper elevation={5} sx={{
                                                        width: '90%',
                                                        color: "black",
                                                        backgroundColor: "lightgray"
                                                    }}>
                                                        <Container sx={{textAlign: 'left'}}>
                                                            <Grid sx={{padding: 3}}>
                                                                <Grid item xs={12}>
                                                                    <Typography fontFamily={'"Raleway", sans-serif'} variant={"h5"}
                                                                                sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                                                        {option.jobDescription}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography fontFamily={'"Raleway", sans-serif'} variant={"p"} sx={{marginTop: 1, fontFamily: '"Raleway", sans-serif'}}>
                                                                        <i>Openings</i>: {option.openings}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography fontFamily={'"Raleway", sans-serif'} variant={"p"} sx={{fontFamily: '"Raleway", sans-serif'}}>
                                                                        <i>Offer</i>: {option.offer}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography fontFamily={'"Raleway", sans-serif'} fontWeight={'bold'} >
                                                                        <i>Remarks</i>: {option.remarks}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Container>
                                                    </Paper>
                                                </Grid> : null
                                        )
                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Field required as={FormControlLabel} control={<CCheckbox sx={{paddingY: 0, marginY: 0}}/>}
                                   type={"checkbox"} name={"agreement"}
                                   label={"For candidates that we find as promising, we would want to offer a CTC on the higher side but will require a bond with a commitment of 2 years valued at ₹ 1 Lakhs"}
                                   value={"yes"}/>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Agreement;