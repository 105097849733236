import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AppBar, Button, styled, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom/";
import {useHistory} from "react-router-dom";

export const CButton = styled(Button)({
    display: 'flex',
    backgroundColor:"white",
    color:'gray',
    fontSize: 20,
    fontFamily: '"Raleway", sans-serif',
    "&:hover":{
        color:'orange',
        backgroundColor:"white",
    }
})

function Topbar() {
    const history = useHistory()

    function handleClick() {
        history.push('/')
        window.location.reload(false)

    }

    return (
        <div>
            <AppBar elevation={1} position="fixed">
                <Toolbar sx={{backgroundColor: "white", justifyContent: 'space-between'}}>
                    <Link to={"/"} style={{textDecoration: "none"}}>
                        <Box
                            component="img"
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                                height: 80,
                                width: 150,
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            onClick={handleClick}
                            alt="The house from the offer."
                            src={require("../../technodeal_logo.png")}
                        />
                    </Link>
                    <Link to={"/admin"} style={{textDecoration: "none"}}>
                        <CButton>
                                Admin
                        </CButton>
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Topbar;