import React from 'react';
import {styled, TextField} from "@mui/material";
import {useField} from "formik";

export const CTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FFC300',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#FFC300',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FFC300',
        },
    },
});

function TextfieldWrapper({
    name,
    ...otherProps
                          }) {
    const [field, meta] = useField(name)
    const configTextField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        fontFamily: 'Raleway, San-serif'
    }
    if(meta && meta.touched && meta.error){
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }
    return (
        <CTextField {...configTextField}/>
    );
}

export default TextfieldWrapper;