import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Paper} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Fragment>
        <Paper sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            border: '0px solid orange'
        }}>
            <App/>
        </Paper>
    </React.Fragment>
);

