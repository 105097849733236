import React, {useEffect, useState} from 'react';
import {
    Box,
    ButtonBase, Card,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    styled,
    Typography
} from "@mui/material";
import {useField} from "formik";
import {CloseButton} from "react-bootstrap";
import axios from "axios";


export const CCheckbox = styled(Checkbox)({
    color: '#FFC300',
    '&.Mui-checked': {
        color: '#FFC300',
    },
})

export const CRadio = styled(Radio)({
    color: '#FFC300',
    '&.Mui-checked': {
        color: '#FFC300',
    },
})

const experience = [
    {
        label: 'Fresher',
        value: 0
    },
    {
        label: '1 Year',
        value: 1
    },
    {
        label: '2 Years',
        value: 2
    },
    {
        label: '3 Year',
        value: 3
    },
    {
        label: '4/4+ Year',
        value: 4
    },
]
// const stream = [
//     'Engineering',
//     'Management'
// ]

// const jobDescription = {
//     jobType: [
//         'Engineering',
//         'Management'
//     ],
//     jobs: [
//         {
//             jobPost: 'Electrical Engineering Interns',
//             openings: '6 Openings',
//             offer: 'Stipend Only - 6 Months',
//             remarks: 'Shall be offered employment based on performance in the 6 Months internship',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Fresher'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Graduate Engineer Trainees',
//             openings: '6 Openings',
//             offer: 'Stipend & Accommodation',
//             remarks: 'Shall be offered 2 Year Contract on successful completion of 6 Months training',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Industrial Engineers', 'Fresher'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Executive Assistants Engineering',
//             openings: '3 Openings',
//             offer: 'CTC :: 2.75 LPA - 3 LPA',
//             remarks: '2 Year Contract',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Industrial Engineers', 'Fresher', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Asst. Site Engineers',
//             openings: '2 Openings',
//             offer: 'CTC :: 2.5 LPA - 3 LPA',
//             remarks: '2 Year Contract',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Fresher', '1 Year', '2 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Asst. Project Engineers',
//             openings: '2 Openings',
//             offer: 'CTC :: 2.5 LPA - 3 LPA',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Industrial Engineers', 'Mechanical Engineers', 'Fresher', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Project Engineers',
//             openings: '2 Openings',
//             offer: 'CTC :: 3 LPA - 4 LPA',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Engineering', 'Electrical Engineers', '1 Year', '2 Years', '3 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'MEP Engineers',
//             openings: '2 Openings',
//             offer: 'CTC :: 4.5 LPA',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Industrial Engineers', 'Mechanical Engineers', '4 Years', '2 Years', '3 Years',],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'MEP Design Engineers',
//             openings: '2 Openings',
//             offer: 'CTC :: 4.5 LPA - 5 LPA',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Engineering', 'Electrical Engineers', 'MEP Engineers', '4 Years', '2 Years', '3 Years',],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Asst. Project Managers',
//             openings: '2 Openings',
//             offer: 'CTC :: 3.5 LPA - 4.5 LPA',
//             remarks: '2 Year Contract (Renewable) with Accommodation options',
//             eligibility: ['Engineering', 'Electrical Engineers', 'Mechanical Engineers', 'Civil Engineers', 'Industrial Engineers', 'Fresher', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Sr. Electrical Design Engineers',
//             openings: '4 Openings',
//             offer: 'CTC :: 5 LPA',
//             remarks: '2 Year Contract (Renewable) with Accommodation options',
//             eligibility: ['Engineering', 'Electrical Engineers', '3 Years', '4 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'Sr. Project Coordinators',
//             openings: '2 Openings',
//             offer: 'CTC :: 4 LPA - 6 LPA',
//             remarks: '2 Year Contract (Renewable) with Accommodation options',
//             eligibility: ['Engineering', 'Electrical Engineers', 'MEP Engineers', '3 Years', '4 Years'],
//             stream: ['Engineering']
//         },
//         {
//             jobPost: 'MBA Interns (Marketing & HR)',
//             openings: '2 Openings',
//             offer: 'Stipend & Accommodation',
//             remarks: 'Pre-Placement Internship with CTC on Offer 3-4 LPA with a 2 Year Contract',
//             eligibility: ['Management', 'MBA (HR / Marketing)', 'Fresher'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'MBA Interns (Finance)',
//             openings: '2 Openings',
//             offer: 'Stipend & Accommodation',
//             remarks: 'Pre-Placement Internship with CTC on Offer for 3 - 5 LPA with a 2 Year Contract',
//             eligibility: ['Management', 'MBA (Finance)', 'Fresher'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Back Office Executives',
//             openings: '3 Openings',
//             offer: 'CTC :: 2 LPA (0 - 2Y Exp)',
//             remarks: '2 Year Contract',
//             eligibility: ['Management', 'MBA', 'BBA', 'B.COM', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Talent Acquisition Specialists',
//             openings: '2 Openings',
//             offer: 'CTC :: 2.5 LPA -  4.5 LPA (0 - 2Y Relevant Exp)',
//             remarks: '2 Year Contract Remote / Hybrid Options',
//             eligibility: ['Management', 'MBA', 'BBA', 'B.COM', 'Electrical Engineers', 'Industrial Engineers', 'Mechanical Engineers', 'MEP Engineers', 'Civil Engineers', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Talent Acquisition Manager',
//             openings: '2 Openings',
//             offer: 'CTC :: 2.5 LPA -  4.5 LPA (2 - 4Y Relevant Exp)',
//             remarks: '2 Year Contract Remote / Hybrid Options',
//             eligibility: ['Management', 'MBA', 'BBA', 'B.COM', '4 Years', '2 Years', '3 Years',],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Executive Assistants MBA',
//             openings: '3 Openings',
//             offer: 'CTC :: 2.5 LPA - 3 LPA (0 - 2Y Exp)',
//             remarks: '2 Year Contract',
//             eligibility: ['Management', 'MBA', 'BBA', 'B.COM', 'Fresher', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Asst. Managers (Operations)',
//             openings: '3 Openings',
//             offer: 'CTC :: 3 - 4 LPA (0 - 2Y Exp)',
//             remarks: '2 Year Contract',
//             eligibility: ['Management', 'MBA', 'Industrial Engineers', 'Fresher', '1 Year', '2 Years', '3 Years', '4 Years'],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Operations Managers',
//             openings: '3 Openings',
//             offer: 'CTC :: 4 - 4.5 LPA (2 - 3Y Exp)',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Management', 'MBA', 'Industrial Engineers', '4 Years', '2 Years', '3 Years',],
//             stream: ['Management']
//         },
//         {
//             jobPost: 'Management Executives',
//             openings: '3 Openings',
//             offer: 'CTC :: 4 - 4.5 LPA (2 - 3Y Exp)',
//             remarks: '2 Year Contract (Renewable)',
//             eligibility: ['Management', 'MBA', 'BBA', 'B.COM', 'Electrical Engineers', 'Industrial Engineers', 'Mechanical Engineers', 'MEP Engineers', 'Civil Engineers', '4 Years', '2 Years', '3 Years'],
//             stream: ['Management']
//         }
//     ]
// }

const underGrad = [
    'B.E./B.Tech (Electrical)',
    'B.E./B.Tech (Industrial)',
    'B.E./B.Tech (Mechanical)',
    'B.E./B.Tech (Civil)',
    'B.Arc.',
    'B.Com',
    'B.B.A.',
    'B.C.A.',
    'B.A.'
]

const postGrad = [
    'M.E./M.Tech. (Electrical)',
    'M.E./M.Tech. (Mechanical)',
    'M.E./M.Tech. (Industrial)',
    'M.E./M.Tech. (Civil)',
    'M.B.A (General)',
    'M.B.A (Marketing)',
    'M.B.A (Finance)',
    'M.B.A (HR)'
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function Openings() {

    const [jobss, setJobss] = useState([{
        id: 0,
        minYear: '',
        maxYear: '',
        postGraduate: [
            {
                id: 0,
                postGraduate: ''
            }
        ],
        underGraduate: [
            {
                id: 0,
                underGraduate: ''
            }
        ],
        degree: [],
        jobDescription: "",
        offer: "",
        openings: 0,
        remarks: "",
    }])
    const [jobsss, setJobsss] = useState([])
    const [underGraduates, setUnderGraduates] = useState([
        {
            id: 0,
            underGraduate: ''
        },
    ])
    const [postGraduates, setPostGraduates] = useState([
        {
            id: 0,
            postGraduate: ''
        },
    ])
    const [exp, setExp] = useState()
    const [ud, setUd] = useState('')
    const [pd, setPd] = useState('')

    const fetchJobs = async () => {
        const response = await axios.get('https://job-application-services.herokuapp.com/api/v1/get/jobs').catch((err) => console.log(err))
        setJobss(response.data)
        setJobsss(response.data)
        console.log(response.data)
    }
    const fetchDegree = async () => {
        const response = await axios.get('https://job-application-services.herokuapp.com/api/v1/get/degree').catch((err) => console.log(err));
        const degrees = response.data
        let under = []
        let post = []

        for(let i = 0; i < underGrad.length; ++i){
            let result = degrees.find(obj => {
                return obj.degree === underGrad[i]
            })

            if(result !== undefined)
                under.push(result)
        }

        for(let i = 0; i < postGrad.length; ++i){
            let result = degrees.find(obj => {
                return obj.degree === postGrad[i]
            })

            if(result !== undefined)
                post.push(result)
        }
        for (let i = 0; i < underGrad.length; ++i) {
            let result = under.find(obj => {
                return obj.degree === underGrad[i]
            })

            console.log(result, underGrad[i])

            if(result === undefined)
                under.push({degree: underGrad[i], type: 'U'})
        }

        for (let i = 0; i < postGrad.length; ++i) {
            let result = post.find(obj => {
                return obj.degree === postGrad[i]
            })

            console.log(result, postGrad[i])

            if(result === undefined)
                post.push({degree: postGrad[i], type: 'P'})
        }

        for (let i = 0; i < degrees.length; ++i) {
            if (degrees[i].type === 'U') {
               if(!under.includes(degrees[i]))
                   under.push(degrees[i])

            } else {
                if(!post.includes(degrees[i]))
                    post.push(degrees[i])
            }
        }
        // console.log(under)
        // console.log(post)
        setUnderGraduates(under)
        setPostGraduates(post)
    }

    const [, meta, helpers] = useField('jobDescription');
    const {value} = meta

    useEffect(() => {
        fetchJobs();
        fetchDegree();
        // if (value !== undefined) {
        //     setJobs([value])
        // }
    }, [])

    const {setValue} = helpers;
    const [open, setOpen] = useState(false)
    const [jobs, setJobs] = useState([])

    function handleChange(v) {
        const newJobs = jobs
        if (newJobs.includes(v)) {
            const index = newJobs.indexOf(v)
            newJobs.splice(index, 1)
        } else {
            newJobs.push(v)
        }
        const idd = newJobs.indexOf("");
        (idd > -1) && newJobs.splice(idd, 1)
        setJobs(newJobs)
        setValue(newJobs)
    }

    function Filter(expr, underD, postD) {
        let newFilter = []
        let finalFilter = []
        const filteredJobs = jobss
        console.log(expr, underD, postD)

        if (expr !== undefined) {
            // Filter Experience
            filteredJobs.map(
                (val, i) => {
                    if (val.minYear === null) {
                        if (expr === val.maxYear) {
                            newFilter.push(val)
                        }
                    } else if (val.maxYear === null) {
                        if (expr === val.minYear) {
                            newFilter.push(val)
                        }
                    } else if (val.minYear <= expr && expr <= val.maxYear) {
                        newFilter.push(val)
                        console.log(val)
                    }
                }
            )
        } else {
            newFilter = jobss
        }

        // Filter Undergraduate Degree
        newFilter.map(
            (val, i) => (
                val.degree.map((e, ip) => (e.degree === underD || e.degree === postD) ? finalFilter.push(val) : null)
            )
        )

        // Filter Postgraduate Degree
        // newFilter.map(
        //     (val, i) => (
        //         val.postGraduate.map((e, ip) => e.postGraduate === postD ? finalFilter.push(val) : null)
        //     )
        // )

        if (finalFilter.length === 0) {
            newFilter = newFilter.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.jobDescription === value.jobDescription
                    ))
            )
            setJobsss(newFilter)
        } else {
            finalFilter = finalFilter.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.jobDescription === value.jobDescription
                    ))
            )
            console.log('hello final')
            setJobsss(finalFilter)
        }
        console.log(newFilter)
        console.log(finalFilter)
    }

    function handleExperience(e) {
        const values = e.target.value

        if (parseInt(values) === 0) {
            setOpen(true)
        }
        Filter(parseInt(values), ud, pd)
        setExp(parseInt(values))
    }

    function handleUnderdegree(e) {
        const values = e.target.value
        Filter(exp, values, pd)
        setUd(values)
    }

    function handlePostdegree(e) {
        const values = e.target.value
        Filter(exp, ud, values)
        setPd(values)
    }

    function handleClose() {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant={'h6'} sx={{fontFamily: '"Raleway", sans-serif ', fontWeight: 'bold'}}>
                                Note to freshers:
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <CloseButton onClick={handleClose}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'p'} sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                For candidates that we find as promising, we would want to offer a CTC on the higher
                                side but
                                will require a bond with a commitment of 2 years valued at ₹ 1 Lakhs.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Grid container spacing={2} sx={{marginTop: 4}} direction={'row'}>
                <Grid item md={3} xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"h5"} sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                Experience
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginX: 0,
                            marginBottom: 1,
                            background: "#000000"
                        }}/>
                        <Grid item xs={12}>
                            <FormGroup
                                id="demo-radio-buttons-group-label">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={e => handleExperience(e)}>
                                    {experience.map(
                                        (option,) => (
                                            <FormControlLabel control={<CRadio/>}
                                                              sx={{fontFamily: '"Raleway", sans-serif ',}}
                                                              value={option.value}
                                                              label={option.label}/>
                                        )
                                    )}
                                </RadioGroup>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sx={{marginTop: 2}}>
                            <Typography variant={"h5"} sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                Undergraduate Degree
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginX: 0,
                            marginBottom: 1,
                            background: "#000000"
                        }}/>
                        <Grid item xs={12}>
                            <FormGroup
                                id="demo-radio-buttons-group-label">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={e => handleUnderdegree(e)}
                                >
                                    {underGraduates.map(
                                        (option,) => (
                                            <FormControlLabel control={<CRadio/>}
                                                              sx={{fontFamily: '"Raleway", sans-serif ',}}
                                                              value={option.degree}
                                                              label={option.degree}/>
                                        )
                                    )}
                                </RadioGroup>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sx={{marginTop: 2}}>
                            <Typography variant={"h5"} sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                Postgraduate Degree
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginX: 0,
                            marginBottom: 1,
                            background: "#000000"
                        }}/>
                        <Grid item xs={12}>
                            <FormGroup
                                id="demo-radio-buttons-group-label">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={e => handlePostdegree(e)}
                                >
                                    {postGraduates.map(
                                        (option,) => (
                                            <FormControlLabel control={<CRadio/>} value={option.degree}
                                                              label={option.degree}
                                                              className="degree"
                                                              sx={{fontFamily: '"Raleway", sans-serif ',}}></FormControlLabel>
                                        )
                                    )}
                                </RadioGroup>
                            </FormGroup>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item md={9} xs={12} sx={{
                    borderColor: 'rgb(230, 161, 41)',
                    borderWidth: 'thick'
                }}>
                    <Card
                        sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 1200,
                            maxWidth: '100%',
                            overflow: "hidden",
                            overflowY: "scroll",
                            borderColor: 'rgb(230, 161, 41)',
                            borderWidth: 'thick',
                            paddingLeft: 12
                        }}>
                        <Grid container spacing={2}>
                            {
                                jobsss.length === 0 ?
                                    <Grid item xs={12}>
                                        <Typography textAlign={'center'}
                                                    sx={{fontFamily: '"Raleway", sans-serif ',}}> No Jobs for current
                                            filters
                                        </Typography>
                                    </Grid>
                                    :
                                    jobsss.map(
                                        (option,) => (
                                            <Grid item xs={12}>
                                                <Paper elevation={5} sx={{
                                                    width: '90%',
                                                    color: jobs.includes(option.jobDescription) ? "#12296C" : "black",
                                                    backgroundColor: jobs.includes(option.jobDescription) || value.includes(option.jobDescription) ? "#FFC300" : "white"
                                                }}
                                                       onClick={e => handleChange(option.jobDescription)}>
                                                    <ButtonBase sx={{textAlign: 'left', width: '100%'}}>
                                                        <Container sx={{textAlign: 'left'}}>
                                                            <Grid sx={{padding: 3}}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant={"h6"}
                                                                                sx={{
                                                                                    fontFamily: '"Raleway", sans-serif ',
                                                                                    fontWeight: 'bold'
                                                                                }}>
                                                                        {option.jobDescription}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant={"p"} sx={{
                                                                        fontFamily: '"Raleway", sans-serif ',
                                                                        marginTop: 1
                                                                    }}>
                                                                        <i>Openings</i>: {option.openings}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant={"p"}
                                                                                sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                                                        <i>Offer</i>: {option.offer}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant={"p"}
                                                                                sx={{fontFamily: '"Raleway", sans-serif ',}}>
                                                                        <i>Remarks</i>: {option.remarks}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Container>
                                                    </ButtonBase>
                                                </Paper>
                                            </Grid>
                                        )
                                    )
                            }
                        </Grid>
                    </Card>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Openings;