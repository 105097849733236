import React, {useState} from 'react';
import {
    Backdrop,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Input,
    MenuItem,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import {CButton} from "../Topbar/Topbar";
import {useFormikContext} from "formik";
import {useHistory} from "react-router-dom";

function LoginForm({Jobs, cred}) {
    const history = useHistory()


    const {values} = useFormikContext()
    const [on, setOn] = useState(false)
    const [selectedFile, setSelectedFile] = useState();
    const [, setIsFilePicked] = useState(false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmission = (e) => {
        e.preventDefault()
        const formData = new FormData();
        setOn(true)

        formData.append('excel_file', selectedFile);
        formData.append('username', values.username);
        formData.append('password', values.password);

        fetch(
            'https://job-application-services.herokuapp.com/api/v1/post/upload',
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.status === 200 ? history.push('/thank-you') : history.push('/error'))
            .then((result) => {
                console.log( result);
                alert('Uploaded Successfully')
                setOn(false)
            })
            .catch((error) => {
                history.push('/error')
                console.error('Error:', error);
                setOn(false)
            });
    };

    const handleDownloadFormat = (e) => {
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: values.username,
                password: values.password,
            })
        };
        setOn(true)
        fetch("https://job-application-services.herokuapp.com/api/v1/post/upload", requestOptions)
            .then((response) => response.status === 200 ? response.blob() : history.push('/error'))
            .then((blob) => {
                if (!blob) {
                    history.push('/error')
                } else {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `Jobs.xlsx`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setOn(false)
                }
            })
            .catch((err) => {
                setOn(false)
                console.log(err)
                history.push('/error')
            })
    }

    return (
        <Paper sx={{marginTop: 20, paddingBottom: 5}}>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={on}
            >
                <CircularProgress sx={{
                    color: "#FFC300"
                }}/>
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Container maxWidth={'md'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={"h4"}
                                            sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                    Credentials
                                </Typography>
                            </Grid>
                            <Divider variant="middle" sx={{
                                width: "100%",
                                borderBottomWidth: 5,
                                marginTop: 1.75,
                                marginBottom: 5,
                                background: "#000000"
                            }}/>
                            <Grid item xs={12} md={6}>
                                <TextfieldWrapper name={"username"} label={"Username"}/>
                            </Grid>
                            <Grid md={6}/>
                            <Grid item xs={12} md={6}>
                                <TextfieldWrapper type={"password"} name={"password"} label={"Password"}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={"h4"}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontFamily: '"Raleway", sans-serif',
                                                marginTop: 5
                                            }}>
                                    Download
                                </Typography>
                            </Grid>
                            <Divider variant="middle" sx={{
                                width: "100%",
                                borderBottomWidth: 5,
                                marginTop: 1.75,
                                marginBottom: 5,
                                background: "#000000"
                            }}/>

                            <Grid item md={6} xs={12}>
                                <TextfieldWrapper select helperText={'Fill to get just zipped document'} name={"job"}
                                                  label={"Job"}>
                                    {Jobs.map(
                                        (option) => (
                                            <MenuItem key={option.jobDescription} value={option.jobDescription}>
                                                {option.jobDescription}
                                            </MenuItem>
                                        )
                                    )}
                                </TextfieldWrapper>
                                <CButton type={'submit'}>
                                    Download Zip
                                </CButton>
                            </Grid>
                            <Grid md={6}/>

                            <Grid item md={6} xs={12}>
                                <TextfieldWrapper helperText={'Fill to get just single document'} name={"cfid"}
                                                  label={"CFID"}/>
                                <CButton sx={{margin: 2}} type={'submit'}>
                                    Download Docx
                                </CButton>
                            </Grid>

                            <Grid md={6}/>

                            <Grid item xs={5}>
                                <Stack spacing={1}>
                                    <Typography sx={{textAlign: 'center', padding: 1}}>
                                        Start Date
                                    </Typography>
                                    <TextfieldWrapper sx={{
                                        justifyContent: 'center'
                                    }} name={"startDate"} type={"date"} label={" "}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                <Stack spacing={5} direction={"column"}>
                                    <Typography sx={{textAlign: 'center', paddingTop: 8}}>
                                        to
                                    </Typography>
                                    <CButton type={'submit'}>
                                        Download xls
                                    </CButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack spacing={1}>
                                    <Typography sx={{textAlign: 'center', padding: 1}}>
                                        End Date
                                    </Typography>
                                    <TextfieldWrapper sx={{
                                        justifyContent: 'center'
                                    }} name={"endDate"} type={"date"} label={" "}/>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={"h4"}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontFamily: '"Raleway", sans-serif',
                                                marginTop: 5
                                            }}>
                                    Update Openings
                                </Typography>
                            </Grid>
                            <Divider variant="middle" sx={{
                                width: "100%",
                                borderBottomWidth: 5,
                                marginTop: 1.75,
                                background: "#000000"
                            }}/>


                            <Grid item xs={12} md={12}>
                                <label htmlFor="contained-button-file">
                                    <Grid container mt={2} spacing={2}>
                                        <Grid item md={10}>
                                            <Grid container alignItems="center" mt={2}>
                                                <Grid item md={5}>
                                                    <Typography>
                                                        Upload Openings:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Input onChange={e => changeHandler(e)} sx={{marginLeft: {md: -10}}}
                                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                           id="contained-button-file" type="file"/>
                                                </Grid>
                                            </Grid>
                                            <Typography sx={{fontStyle: 'italic', color: 'gray'}}>
                                                Please upload the file in xlsx format.
                                            </Typography>

                                        </Grid>
                                        <Grid item md={2}>
                                            <CButton onClick={e => handleSubmission(e)} variant="contained"
                                                     sx={{marginTop: {md: 2}, marginLeft: {md: -10}}}>
                                                Upload
                                            </CButton>
                                        </Grid>

                                        <Grid item md={5}>
                                            <CButton onClick={e => handleDownloadFormat(e)} variant="contained">
                                                Download Jobs
                                            </CButton>
                                        </Grid>

                                    </Grid>
                                </label>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default LoginForm;