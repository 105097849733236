import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import MultiStepForm, {FormStep} from "../../components/StepSection/MultiStepForm";
import LoginForm from "../../components/LoginForm/LoginForm";
import * as Yup from "yup";
import {useHistory} from "react-router-dom";
import axios from "axios";

var today = new Date();
var today2 = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();
today = yyyy + '-' + mm + '-' + dd;
var ddd = String(today2.getDate()+1).padStart(2, '0');
today2 = yyyy + '-' + mm + '-' + ddd;

const FORM_INTERFACE = {
    username: "",
    password: "",
    startDate: today,
    endDate: today2,
    job: "",
    cfid: ""
}

const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    username: Yup.string()
        .required('Required*'),
    password: Yup.string()
        .required('Required*'),
    startDate: Yup.date()
        .required('Required*'),
    endDate: Yup.date()
        .required('Required*'),
})


function Login() {
    const history = useHistory()
    const [on, setOn] = useState(false)
    const [jobs, setJobs] = useState([])

    function handleSubmit(values) {
        setOn(true)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values)
        };
        console.log(values)
        if (values.job === '' && values.cfid === '') {
            console.log('date')
            handleDate(values)
        } else if (values.cfid !== '') {
            console.log(values.cfid)
            handleCfid(values)
        } else if (values.job !== '' && values.cfid === '') {
            fetch("https://job-application-services.herokuapp.com/api/v1/post/admin/download-zip", requestOptions)
                .then((response) => response.status === 200 ? response.blob() : history.push('/error'))
                .then((blob) => {

                    if (!blob) {
                        history.push('/error')
                    } else {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob], {type: 'application/x-zip-compressed'}),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            `compressed_application.zip`,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }
                })
                .then(() => setOn(false))
                .catch(() => {
                    setOn(false)
                    history.push('/error')
                })
        }
    }

    const handleCfid = (values) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values)
        };

        // DOCX
        fetch("https://job-application-services.herokuapp.com/api/v1/post/admin/download-doc", requestOptions)
            .then((response) => response.status === 200 ? response.blob() : history.push('/error'))
            .then((blob) => {

                if (!blob) {
                    history.push('/error')
                } else {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `application.docx`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                }
            })
            .then(() => setOn(false))
            .catch((err) => {
                setOn(false)
                console.log(err)
                history.push('/error')
            })
    }

    const handleDate = (values) => {
        setOn(true)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values)
        };

        // XLS
        fetch("https://job-application-services.herokuapp.com/api/v1/post/admin/download-xls", requestOptions)
            .then((response) => response.status === 200 ? response.blob() : history.push('/error'))
            .then((blob) => {

                if (!blob) {
                    history.push('/error')
                } else {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `applications.xls`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                }
            })
            .then(() => setOn(false))
            .catch(() => {
                setOn(false)
                history.push('/error')
            })
    }

    const fetchJobs = async () => {
        const response = await axios.get('https://job-application-services.herokuapp.com/api/v1/get/jobs').catch((err) => console.log(err))
        setJobs(response.data)
    }

    useEffect(() => {
        fetchJobs()
    }, [])

    return (
        <React.Fragment>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={on}
            >
                <CircularProgress sx={{
                    color: "#FFC300"
                }}/>
            </Backdrop>

                <MultiStepForm
                    initialValues={FORM_INTERFACE}
                    onSubmit={values => handleSubmit(values)}>
                    <FormStep
                        stepName="Download"
                        initialValues={FORM_INTERFACE}
                        validationSchema={LOGIN_VALIDATION_SCHEMA}
                        onSubmit={() => console.log('')}>
                        <LoginForm Jobs={jobs} cred={FORM_INTERFACE}/>
                    </FormStep>

                </MultiStepForm>
        </React.Fragment>
    );
}

export default Login;