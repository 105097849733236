import React, {useState} from 'react';
import {
    Box,
    ButtonBase,
    Checkbox,
    Container,
    Divider,
    FormControlLabel, FormGroup,
    Grid,
    Paper, Stack,
    Typography,
    useTheme
} from "@mui/material";
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import cons from './contact.png'
import {Field, useField} from "formik";
import {CCheckbox} from "../JobDescription/Openings";

function ContactInfo({jobs}) {
    const theme = useTheme()

    const [, meta, helper] = useField('jobDescription')
    const [currAdd, , ] = useField('currentAddress')
    const [currPin, , ] = useField('currentPincode')
    const [, , perAdd] = useField('permanentAddress')
    const [, , perPin] = useField('permanentPincode')
    const {value} = meta
    const {setValue} = helper
    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <Typography fontFamily={'"Raleway", sans-serif'} variant={'h6'}
                                sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                        Selected jobs:
                    </Typography>
                    <Box
                        sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 250,
                            maxWidth: '100%',
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}>
                        <Grid container spacing={2}>
                            {jobs.map(
                                (option,) => (
                                    value.includes(option.jobDescription) ?
                                        <Grid item xs={12}>
                                            <Paper elevation={5} sx={{
                                                width: '90%',
                                                color: "black",
                                                backgroundColor: "#FFC300",
                                            }}
                                                   onClick={(e) => {
                                                       if(value.length === 1){
                                                           return
                                                       }
                                                       const index = value.indexOf(option.jobDescription);
                                                       if (index > -1) {
                                                           value.splice(index, 1);
                                                       }

                                                       setValue(value)
                                                       console.log(value)
                                                   }}>
                                                <ButtonBase sx={{textAlign: 'left', width: '100%'}}>

                                                    <Container sx={{textAlign: 'left'}}>
                                                        <Grid sx={{padding: 3}}>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"h5"}
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                fontFamily: '"Raleway", sans-serif'
                                                                            }}>
                                                                    {option.jobDescription}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"} sx={{
                                                                    marginTop: 1,
                                                                    fontFamily: '"Raleway", sans-serif'
                                                                }}>
                                                                    <i>Openings</i>: {option.openings}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            variant={"p"}
                                                                            sx={{fontFamily: '"Raleway", sans-serif'}}>
                                                                    <i>Offer</i>: {option.offer}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography fontFamily={'"Raleway", sans-serif'}
                                                                            fontWeight={'bold'}>
                                                                    <i>Remarks</i>: {option.remarks}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </ButtonBase>
                                            </Paper>
                                        </Grid> : null
                                )
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Grid>
            <Grid item xs={6} sx={{
                display: 'block',
                [theme.breakpoints.down('sm')]:
                    {
                        display: 'none'
                    }
            }}>
                <img src={cons} alt={'Icon'} style={{display: 'block', margin: 'auto', width: '80%'}}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Container maxWidth={'md'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' variant={"h4"}
                                        sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                Contact Information
                            </Typography>
                        </Grid>
                        <Divider variant="middle" sx={{
                            width: "100%",
                            borderBottomWidth: 5,
                            marginTop: 1.75,
                            marginBottom: 5,
                            background: "#000000"
                        }}/>
                        <Grid item xs={12} md={6}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2}>
                                Current Address
                            </Typography>
                            <TextfieldWrapper required name={"currentAddress"} label={"Current Address"}
                                              multiline={true} rows={4}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1.5} mt={{md: 3.5}}>
                                <Grid item xs={12}>
                                    <TextfieldWrapper required type={'number'} name={"currentPincode"}
                                                      label={"Current Address Pincode"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextfieldWrapper required type={'number'} name={"phoneNo"} label={"Phone Number"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={'row'}>

                            <CCheckbox onChange={(e) => {
                                var checks = e.target.checked
                                if(checks){
                                    perAdd.setValue(currAdd.value)
                                    perPin.setValue(currPin.value)
                                }else{
                                    perAdd.setValue('')
                                    perPin.setValue('')
                                }
                            }}/>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2} marginTop={2}>
                                Same permanent address
                            </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography fontFamily='"Raleway", sans-serif' marginBottom={2} marginTop={2}>
                                Permanent Address
                            </Typography>
                            <TextfieldWrapper required name={"permanentAddress"} label={"Permanent Address"}
                                              multiline={true} rows={4}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1.5} mt={{md: 5.5}}>
                                <Grid item xs={12}>
                                    <TextfieldWrapper required type={'number'} name={"permanentPincode"}
                                                      label={"Permanent Address Pincode"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextfieldWrapper required type={'number'} name={"emergencyContact"}
                                                      label={"Emergency Contact Number"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper required name={"districtState"} helperText={"Eg. Nagpur, Maharashtra"}
                                              label={"District & State"}/>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default ContactInfo;