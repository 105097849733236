import React, {useEffect, useState} from 'react';
import {Container, Divider, Grid, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import {FieldArray, useField} from "formik";
import TextfieldWrapper from "../StepSection/TextfieldWrapper";
import {CButton} from "../Topbar/Topbar";

import education from './education.png'


const reasons = [
    "Looking for Career Growth",
    "Location Constrain",
    "Bad Work Culture",
    "Work Timing",
    "Other",
]

function EmploymentInfo() {
    const [, meta, helper] = useField('noOfWE')
    const {value} = meta
    const {setValue} = helper

    // const {values} = useFormikContext()
    const [flag, setFlag] = useState(false)
    const [empField, , empHelper] = useField('employmentHistory')

    const [state, setState] = useState([])

    function handleIni() {
        const ini = []
        if (value !== undefined) {
            for (let i = 0; i < value; ++i) {
                ini.push(i)
            }
        } else {
            ini.push(0)
        }

        setState(ini)
    }

    useEffect(() => {
        handleIni();
    }, [])

    function handleAdd() {
        const vec = state
        vec.push(state.length)
        setValue(vec.length)
        setState(vec)
    }

    function handleRemove(i) {
        const vec = state
        if (i !== 0 && state.length !== 1)
            vec.pop()

        setValue(vec.length)
        setState(vec)
    }

    const theme = useTheme()


    return (
        <Grid container spacing={5}>
            <Grid item xs={6} sx={{
                display: 'block',
                [theme.breakpoints.down('sm')]:
                    {
                        display: 'none'
                    }
            }}>
                <img alt={'icon'} src={education} style={{display: 'block', margin: 'auto', width: '60%'}}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Container maxWidth={'md'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily='"Raleway", sans-serif' variant={"h4"}
                                        sx={{fontWeight: 'bold', fontFamily: '"Raleway", sans-serif'}}>
                                Employment History
                            </Typography>
                        </Grid>
                        <Divider variant="middle"
                                 sx={{width: "100%", borderBottomWidth: 5, marginTop: 1.75, background: "#000000"}}/>
                        <FieldArray name={"employmentHistory"}>
                            <React.Fragment>
                                {state.map(
                                    (_, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={10} marginTop={index === 0 ? 2 : 5} marginBottom={2}>
                                                <Typography fontFamily='"Raleway", sans-serif' variant={"h5"}>
                                                    Work Experience #{index + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} marginTop={index === 0 ? 2 : 5} marginBottom={2}>
                                                {index !== 0 ? <CButton onClick={e => handleRemove(index)}>
                                                    Remove
                                                </CButton> : null}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextfieldWrapper required label={"Previous Position"}
                                                                  name={`employmentHistory[${index}].currentOrLastPosition`}/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextfieldWrapper required label={"Name Of Employer"}
                                                                  name={`employmentHistory[${index}].nameOfEmployer`}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextfieldWrapper required name={`employmentHistory[${index}].address`}
                                                                  label="Address"
                                                                  multiline={true} rows={4}/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextfieldWrapper required
                                                                  name={`employmentHistory[${index}].jobDuration`}
                                                                  label="Job Duration"
                                                                  helperText="Eg. [ Jun 2016 - Mar 2019 ]   or   [ Jun 2016 - Till Date ]"/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextfieldWrapper required
                                                                  name={`employmentHistory[${index}].reportingManager`}
                                                                  label="Reporting Manager"/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    sx={{
                                                        '& label.Mui-focused': {
                                                            color: 'black',
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: '#FFC300',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#FFC300',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'black',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#FFC300',
                                                            },
                                                        },
                                                    }}
                                                    fullWidth variant={'outlined'} required select
                                                    onChange={(e) => {
                                                        var newVal = e.target.value
                                                        empField.value[index].reasonForLeaving = e.target.value
                                                        empHelper.setValue(empField.value)
                                                        if (newVal === 'Other') {
                                                            setFlag(true)
                                                        } else if (reasons.includes(newVal)) {
                                                            setFlag(false)
                                                        }
                                                    }}
                                                    label={"Reason for leaving"}>
                                                    {reasons.map(
                                                        (option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    )}

                                                </TextField>
                                            </Grid>
                                            {
                                                flag === true ?
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            sx={{
                                                                '& label.Mui-focused': {
                                                                    color: 'black',
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#FFC300',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#FFC300',
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'black',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#FFC300',
                                                                    },
                                                                },
                                                            }}
                                                            fullWidth required variant={'outlined'}
                                                            label={"Reason for leaving"}
                                                            onChange={(e)=>{
                                                                empField.value[index].reasonForLeaving = e.target.value
                                                                empHelper.setValue(empField.value)
                                                            }}>

                                                        </TextField>
                                                    </Grid> : null

                                            }
                                        </React.Fragment>
                                    )
                                )}
                                {state.length < 3 ? <Grid item xs={12}><CButton onClick={handleAdd}>Add
                                    Experience</CButton></Grid> : null}
                            </React.Fragment>
                        </FieldArray>
                    </Grid>
                </Container>
            </Grid>
        </Grid>


    );
}

export default EmploymentInfo;